import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vco from "v-click-outside";
import Service from "@/services/service";
import VueTheMask from "vue-the-mask";
import VueMoney from "v-money";
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import ptBR from "vee-validate/dist/locale/pt_BR.json";
import VueMask from "v-mask";
import VueCookie from "vue-cookie";
import ViaCep from 'vue-viacep';
import dayjs from './plugins/dayjs';

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("pt_br", ptBR);

Vue.config.productionTip = false;
Vue.prototype.$service = Service;

Vue.use(ViaCep);
Vue.use(VueCookie);
Vue.use(VueMask);
Vue.use(VueTheMask);
Vue.use(vco);
Vue.use(VueMoney, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
});
Vue.use(vco);
Vue.use(dayjs);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
