import Vue from "vue";
import VueRouter from "vue-router";
import Marketplace from "@/views/Marketplace";
import ItemDetail from "@/views/ItemDetail";
import Cart from "@/views/Cart";
import Orders from "@/views/Orders";
import Login from "@/views/Login";
import Address from "@/views/Address";
import PurchaseDetail from "@/views/PurchaseDetail";
import Payment from "@/views/Payment";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Marketplace",
    component: Marketplace,
    meta: {
      title: "Marketplace"
    },
  },

  {
    path: "/item/detail/",
    name: "ItemDetail",
    component: ItemDetail,
    meta: {
      title: "Detalhes do item"
    },
  },

  {
    path: "/payment/",
    name: "Payment",
    component: Payment,
    meta: {
      title: "Pagamento Pedido"
    },
  },

  {
    path: "/purchase/detail",
    name: "purchaseDetail",
    component: PurchaseDetail,
    props: true,
    meta: {
      title: "Detalhes do Pedido"
    },
  },

  {
    path: "/cart",
    name: "Cart",
    component: Cart,
    meta: {
      title: "Carrinho"
    },
  },

  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: {
      title: "Pedidos"
    },
  },

  {
    path: "/address",
    name: "Address",
    component: Address,
    meta: {
      title: "Endereço"
    },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Entrar"
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " | Editora Instituto Mix";
  }

  next();
});

export default router;
