<template>
  <default-view :hasHeader="false">
    <template v-slot:content>
      <div class="container-purchase">
        <div class="content-products" v-if="!createdOrder && !isError">
          <div class="contact">
            <h2>Endereço de Entrega</h2>
            <div class="info">
              <p>Nome: {{ user.nome }}</p>
              <p>Endereço: {{ user.endereco }}</p>
              <p>Email: {{ user.email }}</p>
              <p>CNPJ/CPF: {{ user.cnpj.replace("/", "-") }}</p>
              <p>Cidade: {{ user.nome_cidade }}</p>
            </div>
          </div>
          <div class="products" v-if="purchase != null">
            <h2>Produtos</h2>

            <div v-if="isMobile" class="products-detail">
              <h3>Cod.</h3>
              <h3>Val.</h3>
              <h3>Quant.</h3>
              <h3>SubTotal</h3>
            </div>

            <div v-else class="products-detail">
              <h3>Código</h3>
              <h3>Nome</h3>
              <h3>Quantidade</h3>
              <h3>Valor</h3>
              <h3>Subtotal</h3>
            </div>

            <div
              class="items"
              v-for="purchase in purchase.items.items"
              :key="purchase.key"
            >
              <p class="cod-product">{{ purchase.codigo }}</p>
              <p class="name-product">{{ purchase.nome }}</p>
              <p class="qt-product">{{ purchase.qt }}</p>
              <p class="value-product">{{ priceFormatter(purchase.valor) }}</p>
              <p class="subtotal-product">
                {{
                  priceFormatter(
                    parseFloat(purchase.valor) * parseFloat(purchase.qt)
                  )
                }}
              </p>
            </div>
          </div>
          <div class="values-container" v-if="!isLoading && purchase != null">
            <p>Frete: {{ priceFormatter(shippingValue) }}</p>
            <h2>
              Total:
              {{ priceFormatter(purchase.items.purchaseValue + shippingValue) }}
            </h2>

            <button-component
              :hasLoader="loader"
              :isDisabled="loader"
              @click.native="finishPurchase()"
              class="button-component"
              text="Concluir"
            />
          </div>
          <div class="loading" v-else>
            <h2>Carregando...</h2>
          </div>
        </div>
        <div class="created-order" v-else-if="createdOrder">
          <h2>
            <i class="icon-check" />
            Seu pedido foi realizado com sucesso e agora está sendo processado!
          </h2>
          <p>pedido : #{{ orderCreated.order_id }}</p>
          <button-component
            @click.native="toOrders"
            class="button-component"
            text="Ir para meus pedidos"
          />
        </div>
        <div class="error-purchase" v-else>
          <h2>Oops...</h2>
          <p>Algo aconteceu de errado!!!</p>

          <button-component
            @click.native="toCart()"
            class="button-to-cart"
            text="Ir para o Carrinho"
            styles="ghost"
          />
        </div>
      </div>
    </template>
  </default-view>
</template>

<script>
import Vue from "vue";
import DefaultView from "../components/Views/DefaultView.vue";
import { instance } from "../config/index";
import Button from "../components/Button.vue";
import { mapGetters } from "vuex";

export default {
  components: { DefaultView, "button-component": Button },

  data() {
    return {
      isLoading: true,
      isMobile: false,
      isError: false,
      shippingValue: 0,
      purchase: {},
      user: { cnpj: "" },
      items: {},
      loader: false,
      createdOrder: false,
      orderCreated: {},
    };
  },

  computed: {
    ...mapGetters(['getQtBox'])
  },

  created() {
    this.productsDetails();
    this.getScreenSize();
    this.getUser();
  },

  mounted() {
    window.addEventListener("resize", this.getScreenSize);
  },

  methods: {
    getUser() {
      this.$store.dispatch("getUser").then(() => {
        this.user = this.$store.state.user;
        if (this.purchase != null) {
          this.calcShipping();
        }
      });
    },

    calcShipping() {
      if (
        (!this.purchase.items.selectedWithdrawn &&
          this.purchase.items.company == "JAMEF") ||
        (!this.purchase.items.selectedWithdrawn &&
          this.purchase.items.company == "BRASPRESS") 
          ||
        (!this.purchase.items.selectedWithdrawn &&
          this.purchase.items.company == "SAOMIGUEL")
      ) {
        this.$store
          .dispatch("getOrderSize", this.purchase.items.items)
          .then(() => {
            if (this.purchase.items.company == "JAMEF") {
              this.$store.dispatch("CalcJamef").then((response) => {
                if (response == "error") {
                  this.isError = true;
                } else {
                  this.shippingValue = response.valor;
                  this.isLoading = false;
                }
              });
              return;
            }

            if(this.purchase.items.company == "BRASPRESS") {
              this.$store.dispatch("CalcBrasPress").then((response) => {
                if (response == "error") {
                  this.isError = true;
                } else {
                  this.shippingValue = response.totalFrete;
                  this.isLoading = false;
                }
              });

              return;
            }

            if(this.purchase.items.company == "SAOMIGUEL") {
              this.$store.dispatch('getIbgeCod').then(() => {
                this.$store.dispatch('CalcSaoMiguelAPI').then((response) => {
                  if (response == 'error') {
                    this.isError = true;
                } else {
                  this.shippingValue = response.valorFrete;
                  this.isLoading = false;
                }
                });
              });

              return;
            }
          });
      } else {
        this.isLoading = false;
      }
    },

    priceFormatter(value) {
      return this.$service.priceFormatter(value);
    },

    toCart() {
      this.$router.push("/cart");
    },

    finishPurchase() {
      this.loader = true;
      if (
        (this.purchase.items.selectedWithdrawn && this.shippingValue != 0) ||
        (this.purchase.items.company == "A combinar" &&
          this.shippingValue != 0) ||
        (!this.purchase.items.selectedWithdrawn &&
          this.purchase.items.company != "A combinar" &&
          this.purchase.items.valor_frete == 0)
      ) {
        this.isError = true;
        sessionStorage.removeItem("purchase");
      } else {
        this.createOrder();
      }
    },

    async createOrder() {
      const data = {
        order: {
          user_id: Vue.cookie.get("codigoUser"),
          franchise_id: this.user.codigo,
          publishing_id: "005155",
          payment_type: this.purchase.payment_type,
          tax: {
            company: this.purchase.items.company + " - " + this.getQtBox,
            delivery: this.purchase.items.selectedWithdrawn,
            responsavel: this.purchase.funcionario,
            valor_frete: this.shippingValue,
            qt_box: this.getQtBox
          },
          products: this.items,
        },
      };

      await instance()
        .post("/ecommerce/createorder", data)
        .then((response) => {
          localStorage.setItem("itemCart", JSON.stringify([]));
          sessionStorage.removeItem("purchase");
          this.orderCreated = response;
          this.createdOrder = true;
        })
        .catch(() => {
          sessionStorage.removeItem("purchase");
          this.isError = true;
          this.loader = false;
        });
    },

    productsDetails() {
      this.purchase = JSON.parse(sessionStorage.getItem("purchase"));
      if (this.purchase == null) {
        this.$router.push("/cart").catch(() => {});
      } else {
        const products = this.purchase.items.items;
        const items = [];
        products.forEach((element) => {
          items.push({ codigo: element.codigo, quantidade: element.qt });
        });
        this.items = items;
      }
    },
    getScreenSize() {
      if (window.screen.width <= 500) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    toOrders() {
      this.$router.push("/orders").catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.container-purchase {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 75rem;
  background: #fff;
  box-shadow: rgb(40 41 61 / 2%) 0 0.25rem 0.5rem,
    rgb(96 97 112 / 10%) 0 0.5rem 1rem;
  border-radius: 0.3rem;

  .error-purchase {
    padding: 2rem;
    width: 100%;
    color: #a14228;

    .button-to-cart {
      width: 20rem;
      margin: 2rem 0;
      height: 4rem;
    }
  }

  .content-products {
    width: 100%;
    .contact {
      padding: 1rem;

      .info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    .products {
      padding: 1rem;
      .products-detail {
        padding: 0.2rem;
        margin: 0.5rem 0;
        display: grid;
        grid-template-columns: 0.4fr 2fr 0.6fr 0.4fr 0.4fr;
      }

      .items:nth-child(odd) {
        background: #ddd;
      }

      .items {
        background-color: #f8f8f8;
        padding: 0.3rem 0 0.3rem 0.3rem;
        display: grid;
        grid-template-columns: 0.4fr 2fr 0.6fr 0.4fr 0.4fr;

        :nth-child(odd) {
          font-weight: 700;
        }
      }
    }

    .loading {
      margin: 1rem;
      display: flex;
      justify-content: flex-end;
    }

    .values-container {
      margin: 1rem;
      display: flex;
      align-items: baseline;
      justify-content: flex-end;

      .button-component {
        justify-content: center;
        margin-left: 1rem;
        height: 3rem;
        max-width: 15rem;
      }
    }
  }

  .created-order {
    padding: 2rem;
    max-width: 25rem;

    h2,
    h3 {
      font-weight: 600;
      color: green;
    }

    p {
      margin: 1rem 0 1rem 0;
      font-weight: 300;
    }

    .button-component {
      height: 3rem;
      max-width: 15rem;
    }
  }
}

@media (max-width: 1100px) {
  .container {
    .contact {
      .info {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}

@media (max-width: 680px) {
  .container-purchase {
    .contact {
      .info {
        grid-template-columns: 1fr !important;
      }
    }
    .products {
      .products-detail {
        grid-template-columns: repeat(4, 1fr) !important;
      }
      .items {
        grid-template-columns: repeat(4, 1fr) !important;
        .name-product {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-column-end: 5;
        }

        .subtotal-product {
          grid-column-start: 4;
        }

        .qt-product {
          grid-column-start: 3;
          justify-items: center;
        }
        .value-product {
          grid-row-start: 1;
          grid-column-start: 2;
        }
      }
    }

    .error-purchase {
      padding: 2rem;
      width: 100%;
      color: #a14228;

      .button-to-cart {
        width: 100%;
        margin: 2rem 0;
        height: 4rem;
      }
    }
    .values-container {
      flex-wrap: wrap !important;

      .button-component {
        margin: 1rem 0 0 !important;
        max-width: 100% !important;
      }
    }
  }
}
</style>
