<template>
  <div class="ordercard-container">
    <div class="content" @click="Open">
      <h3>{{ orderProps.codigo }}</h3>
      <div class="order-info">
        <p class="cardname">{{ orderProps.nome }}</p>
        <p class="date">
          Realizado em: {{ orderProps.data_cadastro_formatada }} às
          {{ orderProps.hora_cadastro_formatada }}
        </p>
      </div>

      <div class="dispatch">
        <p v-if="orderProps.data_envio_formatada != ''">
          Enviado em: {{ orderProps.data_envio_formatada }}
        </p>
      </div>

      <div class="status">
        <p v-bind:class="getStatus()">
          {{ orderProps.status }}
          <i v-bind:class="iconClass" />
        </p>
      </div>

      <div class="total">
        <p>
          {{
            formatPrice(orderProps.total + parseFloat(orderProps.valor_frete))
          }}
        </p>
      </div>

      <div class="tracking">
        <p>{{ orderProps.cod_rastreio }}</p>
      </div>
    </div>

    <div v-if="open == true" class="showModal">
      <orders-modal-component
        @close="close"
        :showModal="true"
        title="Detalhes do pedido"
        :ordersInfo="orderProps"
      />
    </div>
  </div>
</template>

<script>
import OrdersModal from "@/components/OrdersModal";

export default {
  name: "ordersCard",

  components: {
    "orders-modal-component": OrdersModal,
  },

  data() {
    return {
      styles: null,
      open: false,
    };
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          order: {},
        };
      },
    },

    orderProps: {
      type: Object,
    },
  },

  methods: {
    Open() {
      this.open = true;
      this.$emit("open");
    },
    close() {
      this.open = false;
      this.$emit("close");
    },

    formatHour() {
      this.orderProps.hora_cadastro_formatada =
        this.orderProps.hora_cadastro_formatada.substring(
          0,
          this.orderProps.hora_cadastro_formatada.length - 3
        );
    },

    getStatus() {
      if (this.orderProps.status == "Cancelado") {
        this.iconClass = "icon-close";
        return "cancell";
      }
      if (this.orderProps.status == "Despachado") {
        this.iconClass = "icon-truck";
        return "dispatch";
      }
      if (this.orderProps.status == "Em produção") {
        this.iconClass = "icon-configure";
        return "production";
      }
      if (this.orderProps.status == "Pendência Financeira") {
        this.iconClass = "icon-deposit-error";
        return "pending";
      }
      if (this.orderProps.status == "Aguardando Pagto") {
        this.iconClass = "icon-deposits";
        return "awaiting";
      } else {
        this.iconClass = "icon-spinner";
        return "review";
      }
    },

    formatPrice(value) {
      return this.$service.priceFormatter(value);
    },
  },

  mounted() {
    this.formatHour();
    this.getStatus();
  },
};
</script>

<style lang="scss" scoped>
.content {
  align-items: center;
  background: #fff;
  border-radius: 0.3rem;
  box-shadow: rgb(40 41 61 / 2%) 0 0.25rem 0.5rem,
    rgb(96 97 112 / 4%) 0 0.5rem 1rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-items: center;
  margin: 1rem 0;
  min-height: 5rem;

  p {
    font-weight: 500;
  }

  &:hover {
    box-shadow: rgb(40 41 61 / 4%) 0 0.25rem 0.5rem,
      rgb(96 97 112 / 16%) 0 0.5rem 1rem;
  }

  p.date {
    color: #777777;
  }

  .status {
    & p.review {
      color: #ff8b00;
    }

    & p.production {
      color: #ff8b00;
    }

    & p.pending {
      color: #aa1428;
    }

    & p.awaiting {
      color: #ff8b00;
    }

    & p.dispatch {
      color: #008a0b;
    }

    & p.cancell {
      color: #aa1428;
    }
  }
}

@media (max-width: 575px) {
  .content {
    padding: 1rem;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 0.2rem;
    justify-items: start;
    // grid-auto-rows: 6rem;
    h3 {
      grid-column-start: 1;
      grid-column-end: 2;
    }
    .tracking {
      grid-row-start: 1;
      grid-column-start: 2;
      grid-column-end: 4;
    }
    .order-info {
      grid-column-start: 1;
      grid-column-end: 4;
    }
    .total {
      grid-column-start: 1;
      grid-row-start: 4;
    }
    .status {
      grid-row-start: 4;
      grid-column-start: 2;
      grid-column-end: 4;
    }

    .dispatch {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}
</style>
