<template>
  <header :class="{ 'empty-text': !data.text }" :style="styleObject">
    <div class="nav-container">
      <div class="content">
        <div
          class="logo-container animate__animated animate__pulse"
          @click="toMarketplace"
        ></div>

        <div class="actions-container">
          <nav-bar-action-component
            v-for="(item, index) in actions"
            :key="index"
            :data="item"
          />
        </div>
      </div>

      <input-field-component
        v-model="search_value"
        v-if="hasSearchInput"
        class="input-field-component"
        placeholder="Procurar..."
        :isSearch="true"
        @searchClick="search()"
      />
    </div>
  </header>
</template>

<script>
import NavBarAction from "@/components/Base/NavBarAction";
import InputField from "@/components/InputField";
import "animate.css";

export default {
  name: "NavBar",

  components: {
    "nav-bar-action-component": NavBarAction,
    "input-field-component": InputField,
  },

  data() {
    return {
      search_value: "",
    };
  },

  watch: {
    $route(newValue, oldValue) {
      if (newValue.query.search != oldValue.query.search) {
        this.search_value = this.$route.query.search;
      }
    },
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          backgroudColor: "#AA1428",
          imgName: "logo-instmix.png",
        };
      },
    },

    actions: {
      type: Array,
      default: () => [
        {
          text: "Cart",
          value: "cart",
          icon: "icon-cart",
          path: "/cart",
          amount: 0,
        },

        {
          text: "Login",
          value: "login",
          icon: "icon-user",
          dropdownData: {
            options: [
              {
                label: "Item",
                value: "item1",
                isBold: true,
              },

              {
                label: "Item",
                value: "item2",
              },
            ],
          },
        },
      ],
    },

    hasSearchInput: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    styleObject: function () {
      return {
        "--background-color": this.data.backgroudColor,
      };
    },
  },

  methods: {
    toMarketplace() {
      this.$router.push("/").catch(() => {});
    },

    search() {
      if (this.search_value != this.$route.query.search) {
        this.$router.push({
          name: "Marketplace",
          query: {
            search: this.search_value,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  z-index: 1000;
  background: var(--background-color);
  padding: 0rem 2rem;
  font-size: 0.875rem;
  font-weight: 400;
  min-height: 5.3rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .nav-container {
    width: 100%;
    display: flex;
    align-items: center;
    max-width: 87.5rem;
    .content {
      display: flex;
      width: 100%;

      .logo-container {
        cursor: pointer;
        background-image: url("~@/assets/images/logo-instmix.png");
        background-repeat: no-repeat;
        background-size: cover;
        width: 14.5rem;
        height: 3.1rem;
      }

      .actions-container {
        margin-left: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.5rem;
      }
    }
    .input-field-component {
      position: absolute;
      margin-bottom: 0;
      max-width: 18.75rem;
      margin-left: 16rem;
    }
  }
}

@media (max-width: 835px) {
  header {
    .nav-container {
      flex-direction: column;

      .input-field-component {
        position: unset;
        margin-left: unset;
        margin-top: 1rem;
        max-width: unset;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media (max-width: 540px) {
  header {
    padding: 0rem 1rem;
    .nav-container {
      padding: 1rem 0;

      .content {
        .logo-container {
          background-image: url("~@/assets/images/logo-instmix-min.png");
          width: 4rem;
        }
      }
    }
  }
}
</style>
