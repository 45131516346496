<template>
  <button
    :disabled="isDisabled"
    :class="
      isDisabled
        ? `${styles} disabled ${size} ${scale} ${hasLoader} ${
            hasLoader ? 'spinner' : ''
          }`
        : `${styles} ${size} ${scale} ${hasLoader ? 'spinner' : ''}`
    "
    :type="pathView ? 'button' : 'submit'"
    @click="itemClick, redirect"
  >
    <spinner-component
      v-if="hasLoader"
      :fontSize="buttonFontSize"
      :color="isDisabled ? '#8E8E8E' : '#F36C01'"
    />

    <div v-else class="content">
      <div v-if="!!iconLeft" class="icon-left-container">
        <i :class="iconLeft" />
      </div>

      {{ text }}

      <div v-if="!!iconRigth" class="icon-right-container">
        <i :class="iconRigth" />
      </div>
    </div>
  </button>
</template>

<script>
import Spinner from "@/components/Spinner";

export default {
  name: "Button",

  props: {
    text: {
      type: String,
      default: "text",
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    hasLoader: {
      type: Boolean,
      default: false,
    },

    iconLeft: {
      type: String,
      required: false,
    },

    iconRigth: {
      type: String,
      required: false,
    },

    pathView: {
      type: String,
      default: null,
    },

    scale: {
      type: String,
      default: "full",
    },

    size: {
      type: String,
      default: "",
    },

    buttonFontSize: {
      type: String,
      required: false,
    },

    styles: {
      type: String,
      default: "primary",
    },
  },

  components: {
    "spinner-component": Spinner,
  },

  methods: {
    itemClick() {
      if (!this.isDisabled) {
        this.$emit("itemClick");
      }
    },

    redirect() {
      if (window.location.pathname != this.pathView && this.pathView != null)
        this.$router.push(this.pathView);
      else if (window.location.pathname == this.pathView)
        console.error("Error: Cannot redirect to the same view");
    },
  },
};
</script>

<style lang="scss" scoped>
button {
  position: relative;
  font-size: 0.875rem;
  font-weight: 500;
  min-height: 1.25rem;
  max-height: 3rem;
  border: none;
  border-radius: 0.3rem;
  outline: none;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  display: flex;

  &.full {
    width: 100%;
  }

  &.primary {
    color: #fff;
    background: #ff8b00;

    &:hover {
      background-color: #f36c01;
    }

    &:active {
      background-color: #f36c01;
    }
  }

  &.secondary {
    width: auto;
    color: #ff8b00;
    background: transparent;

    &:hover {
      color: #ff8b00;
      background: rgba(255, 156, 0, 0.1);
    }

    &:active {
      color: #ff8b00;
      background: rgba(255, 156, 0, 0.1);
    }
  }

  &.ghost {
    color: #ff8b00;
    background: transparent;
    border: 0.01rem solid #ff8b00;

    &:hover {
      color: #ff8b00;
      background: rgba(255, 156, 0, 0.1);
      border: 0.01rem solid #ff8b00;
    }

    &:active {
      color: #ff8b00;
      background: rgba(255, 156, 0, 0.1);
      border: 0.01rem solid #ff8b00;
    }

    &.disabled {
      border-color: #8e8e8e;
    }
  }

  &.red {
    color: #fff;
    background: #aa1428;
    border-radius: 0 3rem 3rem 0;

    &:hover {
      background-color: #880f1f;
    }

    &:active {
      background-color: #880f1f;
    }
  }

  &.edit {
    color: #fff;
    background: #ff8b00;
    border-radius: 3rem 0 0 3rem;

    &:hover {
      background-color: #f36c01;
    }

    &:active {
      background-color: #f36c01;
    }
  }

  &.grey {
    color: #fff;
    background: #8e8e8e;
    border-radius: 0;

    &:hover {
      background-color: #6b6b6b;
    }

    &:active {
      background-color: #757575;
    }
  }

  &.spinner {
    min-width: 6.6rem;
  }

  &.disabled {
    opacity: 0.6;
    background: #efefef !important;
    color: #8e8e8e;
    border: 0.01rem solid #8e8e8e;
    cursor: not-allowed;
  }

  &.large {
    height: 3rem;
  }

  &.medium {
    height: 2.5rem;
  }

  &.small {
    height: 2rem;
  }

  .content {
    display: flex;
    padding: 0 1rem;
    font-weight: 500;

    .icon-left-container {
      margin-right: 0.6rem;
    }

    .icon-right-container {
      margin-left: 0.6rem;
    }
  }

  .spinner {
    display: inline-block;
    position: relative;
    width: 1.8rem;
    height: 1.8rem;

    div {
      position: absolute;
      background: #fff;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 0.6rem;
      animation: spin 1.2s linear infinite;
    }

    div:nth-child(1) {
      left: 0;
      top: 0.75rem;
      animation-delay: -1.05s;
    }

    div:nth-child(2) {
      left: 0.1rem;
      top: 0.1rem;
      animation-delay: -0.9s;
    }

    div:nth-child(3) {
      top: 0;
      left: 0.75;
      animation-delay: -0.75s;
    }

    div:nth-child(4) {
      right: 0.1rem;
      top: 0.1rem;
      animation-delay: -0.6s;
    }

    div:nth-child(5) {
      right: 0;
      top: 0.75;
      animation-delay: -0.45s;
    }

    div:nth-child(6) {
      right: 0.1rem;
      bottom: 0.1rem;
      animation-delay: -0.3s;
    }

    div:nth-child(7) {
      left: 0.75rem;
      bottom: 0;
      animation-delay: -0.15s;
    }

    div:nth-child(8) {
      left: 0.1rem;
      bottom: 0.1rem;
      animation-delay: 0s;
    }

    &.disabled {
      div {
        background: #8e8e8e;
      }
    }

    &.ghost {
      div {
        background: #ff8b00;
      }
    }

    &.red {
      div {
        background: #aa1428;
      }
    }
  }

  @keyframes spin {
    0%,
    20%,
    80%,
    100% {
      transform: scale(0.5);
    }

    50% {
      transform: scale(1);
    }
  }
}

@media (max-width: 420px) {
  button {
    .content {
      .icon-left-container {
        display: flex;
        align-items: center;
        margin-right: 0.3rem;
      }
    }
  }
}
</style>
