<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="inputName"
    :rules="!isDisabled ? rules : ''"
    class="input-field-container"
    :class="{
      'has-icon': isSearch || (isPassword() && !!innerValue),
      search: isSearch,
      qt: isQt,
    }"
    v-slot="{ errors }"
  >
    <div class="input-container">
      <input
        v-if="!!mask && mask != 'phone' && mask != 'accountNumber'"
        ref="input"
        @focus="handleFocus"
        @blur="handleBlur"
        :class="{
          error: errors[0],
          default: !errors[0],
          disabled: isDisabled,
          'hide-caret': !hasCaret,
        }"
        v-mask="mask"
        :type="inputType"
        :value="value"
        :maxlength="maxLength"
        :placeholder="isSearch ? placeholder : ' '"
        autocomplete="off"
        v-model="innerValue"
        :disabled="isDisabled"
        @click="setEndInput"
        @input="setEndInput"
      />

      <input
        v-else-if="mask == 'phone'"
        ref="input"
        @focus="handleFocus"
        @blur="handleBlur"
        v-mask="['+55 (##) ####-####', '+55 (##) #####-####']"
        :class="{
          error: errors[0],
          default: !errors[0],
          disabled: isDisabled,
          'hide-caret': !hasCaret,
        }"
        :type="inputType"
        :value="value"
        :maxlength="maxLength"
        :placeholder="isSearch ? placeholder : ' '"
        autocomplete="off"
        v-model="innerValue"
        :disabled="isDisabled"
      />

      <input
        v-else-if="mask == 'accountNumber'"
        ref="input"
        @focus="handleFocus"
        @blur="handleBlur"
        v-mask="[
          '#####-#',
          '######-#',
          '#######-#',
          '########-#',
          '#########-#',
          '###########-#',
        ]"
        :class="{
          error: errors[0],
          default: !errors[0],
          disabled: isDisabled,
          'hide-caret': !hasCaret,
        }"
        :type="inputType"
        :value="value"
        :maxlength="maxLength"
        :placeholder="isSearch ? placeholder : ' '"
        autocomplete="off"
        v-model="innerValue"
        :disabled="isDisabled"
      />

      <money
        v-else-if="isMoneyInput()"
        ref="input"
        @focus="handleFocus"
        @blur="handleBlur"
        :class="{ error: errors[0], default: !errors[0], disabled: isDisabled }"
        :style="{ 'caret-color': 'transparent', 'user-select': 'none' }"
        :maxlength="maxLength"
        :placeholder="isSearch ? placeholder : ' '"
        autocomplete="off"
        v-model="innerValue"
        :disabled="isDisabled"
        @input="setEndInput"
        @click.native="setEndInput"
        @keyup.native.left="setEndInput"
        @keyup.native.right="setEndInput"
        @keyup.native.up="setEndInput"
        @keyup.native.down="setEndInput"
      />

      <input
        v-else
        ref="input"
        @focus="handleFocus"
        @blur="handleBlur"
        :class="{
          error: errors[0],
          default: !errors[0],
          disabled: isDisabled,
          'hide-caret': !hasCaret,
        }"
        @keydown.enter.prevent="enter"
        :type="inputType"
        :value="value"
        :maxlength="maxLength"
        :placeholder="isSearch ? placeholder : ' '"
        autocomplete="off"
        v-model="innerValue"
        :disabled="isDisabled"
      />

      <label v-if="!isSearch && !isQt" :class="{ disabled: isDisabled }">{{
        placeholder
      }}</label>

      <div
        v-if="isPassword() && !!innerValue"
        class="icon-container"
        @click="toggleVisibility"
      >
        <i v-if="visibility" class="icon-eye-slash" />
        <i v-else class="icon-eye" />
      </div>

      <div v-if="isSearch" class="icon-container" @click="searchClick">
        <i class="icon-search" />
      </div>
    </div>

    <div v-if="passwordValidation && !!innerValue" class="password-validation">
      <div class="left">
        <div class="validation" :class="hasSize">
          <i class="icon-circle" />

          <p>Mínimo de 6 caracteres</p>
        </div>

        <div class="validation" :class="hasUpperLowCase">
          <i class="icon-circle" />

          <p>Letras maiúscula e minúscula</p>
        </div>
      </div>

      <div class="right">
        <div class="validation" :class="hasNumber">
          <i class="icon-circle" />

          <p>Números</p>
        </div>

        <div class="validation" :class="hasSpecialCharacter">
          <i class="icon-circle" />

          <p>Caracteres especiais</p>
        </div>
      </div>
    </div>

    <div v-if="isPasswordValidationAndEmpty && !isFocus() && !isDisabled">
      <span class="errors">{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { Money } from "v-money";

export default {
  name: "InputFile",

  props: {
    type: {
      type: String,
      default: "text",
      validator(value) {
        return [
          "url",
          "text",
          "password",
          "tel",
          "search",
          "number",
          "email",
          "money",
        ].includes(value);
      },
    },

    vid: {
      type: String,
      default: undefined,
    },

    placeholder: {
      type: String,
      default: "Placeholder",
    },

    mask: {
      type: String,
      required: false,
    },

    rules: {
      type: [Object, String],
      default: "",
    },

    passwordValidation: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      default: "",
    },

    maxLength: {
      type: Number,
      required: false,
    },

    name: {
      type: String,
      required: false,
    },

    hasFocus: {
      type: Boolean,
      default: false,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    isCurrency: {
      type: Boolean,
      default: false,
    },

    hasCaret: {
      type: Boolean,
      default: true,
    },

    isSearch: {
      type: Boolean,
      required: false,
    },

    isQt: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    ValidationProvider,
    Money,
  },

  data() {
    return {
      innerValue: "",
      visibility: false,
      inputType: "text",
      focusStatus: false,
      content: this.value,
      inputName: "",
      hasSize: null,
      hasNumber: null,
      hasSpecialCharacter: null,
      hasUpperLowCase: null,
    };
  },

  created() {
    this.inputType = this.type;

    if (this.isMoneyInput()) {
      this.inputType = "text";
    }

    if (this.value) {
      this.innerValue = this.value;
    }
  },

  mounted() {
    if (this.name) this.inputName = this.name;
    else this.inputName = this.placeholder.toLowerCase();
  },

  watch: {
    innerValue(value) {
      this.$emit("input", value);

      if (this.passwordValidation) {
        this.verifyPassword(value);
      }
    },

    value(value) {
      if (value !== this.innerValue) {
        this.innerValue = value;
      }
    },
  },

  methods: {
    verifyPassword(value) {
      let regNumber = /[0-9]/;
      let regSpecialCharacter = /^(?=.*[@!#$%^&*()/\\])/;
      let regUpperLowCase = /[A-Z]/;

      if (value.length > 6) this.hasSize = "success";
      else this.hasSize = "error";

      if (regNumber.test(value)) this.hasNumber = "success";
      else this.hasNumber = "error";

      if (regSpecialCharacter.test(value)) this.hasSpecialCharacter = "success";
      else this.hasSpecialCharacter = "default";

      if (regUpperLowCase.test(value)) this.hasUpperLowCase = "success";
      else this.hasUpperLowCase = "default";
    },

    isMoneyInput() {
      return this.type == "money";
    },

    handleFocus() {
      this.focusStatus = true;
      // this.setEndInput();
    },

    handleBlur() {
      this.$emit("att");
      this.focusStatus = false;
      this.$emit("blur", true);
    },

    isFocus() {
      return this.focusStatus;
    },

    isPassword() {
      if (this.type == "password") return true;
      return false;
    },

    isPasswordValidationAndEmpty() {
      if (this.passwordValidation && !!this.innerValue) return true;
      return false;
    },

    toggleVisibility() {
      if (this.visibility) {
        this.visibility = false;
        this.inputType = "password";
      } else {
        this.visibility = true;
        this.inputType = "text";
      }
    },

    enter() {
      if (this.type == "password") {
        this.$emit("LoginEnter");
      }
      if (this.isSearch) {
        this.$store.commit("searchInput", this.innerValue);
        this.$emit("searchClick");
      }
    },

    searchClick() {
      this.$store.commit("searchInput", this.innerValue);
      this.$emit("searchClick");
    },

    setEndInput() {
      if (!this.$service.isEmptyObject(this.$refs)) {
        let input = this.isMoneyInput()
          ? this.$refs.input.$el
          : this.$refs.input;
        input.setSelectionRange(input.value.length, input.value.length);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-field-container {
  margin-bottom: 1rem;
  width: 100%;

  &.search {
    .input-container {
      input {
        padding: 0 2.8rem 0 1rem;

        &::-webkit-input-placeholder {
          color: #8e8e8e;
        }
      }
    }
  }

  &.qt {
    .input-container {
      input {
        text-align: center;
        padding: 0 1rem;
        height: 2rem;
      }
    }
  }

  .input-container {
    position: relative;

    .icon-container {
      padding: 0 0 0 0.6rem;
      position: absolute;
      top: 0.875rem;
      right: 0.875rem;

      i {
        cursor: pointer;
        font-size: 1.3rem;
        color: #8e8e8e;
      }
    }

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      color: #333;
      font-size: 0.875rem;
      display: block;
      border-radius: 0.3rem;
      height: 3rem;
      width: 100%;
      background: transparent;
      border: 0.1rem solid;
      padding: 1rem 1rem 0 1rem;
      font-weight: 300;
      caret-color: #ff8b00;
      background: #fff;

      &:-webkit-autofill {
        font-family: "Poppins", sans-serif;

        & ~ label {
          top: -1.25rem;
        }
      }

      .hide-caret {
        caret-color: transparent;
      }

      &.error {
        border-color: #b93a5b;
      }

      &.success {
        border-color: #07a96e;
      }

      &.default {
        border-color: #dadada;

        &.disabled {
          opacity: 0.8;
          cursor: not-allowed;
          border-color: #dadada;
          background: #f9f9f9;
        }
      }

      &:focus {
        outline: none;
        border-color: #ff8b00;
      }

      &[type="number"]::-webkit-inner-spin-button,
      &[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    label {
      color: #333;
      font-size: 0.875rem;
      position: absolute;
      pointer-events: none;
      left: 1rem;
      top: 1rem;
      white-space: nowrap;
      overflow: hidden;
      max-width: 80%;
      font-weight: 300;
      user-select: none;
      transition: 0.07s ease all;
      -moz-transition: 0.07s ease all;
      -webkit-transition: 0.07s ease all;

      &.disabled {
        opacity: 0.6;
        cursor: not-allowed;
      }
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label {
      color: #8e8e8e;
      font-weight: 400;
      font-size: 0.75rem;
      font-weight: 400;
      top: 0.3rem;

      &.error {
        color: #b93a5b;
      }

      &.success {
        color: #12b16e;
      }
    }
  }

  .password-validation {
    display: flex;
    margin-top: 1rem;

    .right {
      margin-left: auto;
    }

    .validation {
      margin-bottom: 0.5rem;
      display: flex;

      i {
        font-size: 0.4rem;

        &.icon-circle {
          display: flex;
          align-items: center;
          margin-right: 0.5rem;
        }
      }

      p {
        font-size: 0.75rem;
      }

      &.success {
        color: #12b16e;
      }

      &.error {
        color: #b93a5b;
      }

      &.default {
        color: #8e8e8e;
      }
    }
  }
  span {
    color: #b93a5b;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    font-weight: 400;
  }
}

@media (max-width: 1500px) {
  .input-field-container {
    .password-validation {
      flex-direction: column;

      .right {
        margin: 0;
      }
    }
  }
}
</style>
