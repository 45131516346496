<template>
  <default-view-component :hasHeader="false">
    <template v-slot:content>
      <div class="container">
        <div class="content" v-if="isAuthenticated">
          <h2>Meus Pedidos</h2>

          <div class="filter">
            <date-picker-component
              :styles="styles"
              :locale-config="localeConfigs"
              @submit="setDate"
              clearable
              @clear="clearDate"
              locale="pt"
              div-style="width:300px"
              input-format="DD/MM/YYYY"
              modal
            ></date-picker-component>
            <div class="dropdown">
              <dropdown-component
                class="my-dropdown-toggle"
                :options="arrayOfObjects"
                :selected="object"
                v-on:updateOption="methodToRunOnSelect"
                :placeholder="'Status'"
                :closeOnOutsideClick="true"
              ></dropdown-component>
              <i class="icon-close" @click="clearFilter"></i>
            </div>
          </div>

          <order-card-component
            v-for="order in orders"
            :key="order.key"
            :orderProps="order"
          />

          <div v-if="noOrder">
            <h2>Nenhum pedido Encontrado</h2>
          </div>

          <pagination-button-component
            v-if="pageLength > 1"
            class="pagination-button-component"
            :pageProps="pageLength"
            :currentPage="currentPage"
            @nextPage="nextPage()"
            @previousPage="previousPage()"
          />
        </div>
      </div>
    </template>
  </default-view-component>
</template>

<script>
import DefaultView from "@/components/Views/DefaultView";
import { mapGetters } from "vuex";
import { instance } from "../config/index";
import OrdersCard from "@/components/OrdersCard";
import PaginationButton from "@/components/PaginationButton";
import datePicker from "@alireza-ab/vue-persian-datepicker";
import dropdown from "vue-dropdowns";
import Vue from "vue";

export default {
  name: "Orders",

  components: {
    "default-view-component": DefaultView,
    "order-card-component": OrdersCard,
    "pagination-button-component": PaginationButton,
    "date-picker-component": datePicker,
    "dropdown-component": dropdown,
  },
  data() {
    return {
      styles: {
        "primary-color": "red",
        "in-range-background": "#ffc988",
        width: "100px",
      },
      localeConfigs: {
        pt: {
          calendar: "gregorian",
          weekdays: ["D", "S", "T", "Q", "Q", "S", "S"],
          months: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
          ],
          dir: {
            input: "ltr",
            picker: "ltr",
          },
          translations: {
            text: "Selecione as datas",
            now: "Hoje",
            prevMonth: "Mês passado",
            nextMonth: "Próximo mês",
          },
          inputFormat: "date",
        },
      },
      arrayOfObjects: [
        { name: "Em análise", value: "Em análise" },
        { name: "Despachado", value: "Despachado" },
        { name: "Em produção", value: "Em produção" },
        { name: "Pendência Financeira", value: "Pendência Financeira" },
        { name: "Aguardando Pagamento", value: "Aguardando Pagto" },
        { name: "Cancelado", value: "Cancelado" },
      ],
      object: {
        name: "Status",
        value: "",
      },
      noOrder: false,
      initialDate: "",
      endDate: "",
      isAuthenticated: false,
      orders: {},
      pageLength: 0,
    };
  },

  computed: {
    ...mapGetters(["getAuthenticatedStatus"]),
  },

  methods: {
    clearDate() {
      this.initialDate = "";
      this.endDate = "";
      this.getOrders();
    },

    clearFilter() {
      this.object.name = "Status";
      this.object.value = "";
      this.getOrders();
    },

    methodToRunOnSelect(payload) {
      this.object = payload;
      this.getOrders();
    },

    setDate(date) {
      let start = date[0];
      this.initialDate =
        start.d.year + "-" + start.d.month + "-" + start.d.date;
      let end = date[1];
      this.endDate = end.d.year + "-" + end.d.month + "-" + end.d.date;
      this.getOrders();
    },

    async getOrders() {
      const data = {
        franchise_id: Vue.cookie.get("codigoUser"),
        limit_items: 20,
        current_page: this.currentPage,
        start_date: this.initialDate,
        end_date: this.endDate,
        order_status: this.object.value,
      };

      await instance()
        .post("/ecommerce/orders", data)
        .then((response) => {
          const data = response.items;
          this.orders = data;
          this.pageLength = response.total_pages;
          this.currentPage = response.current_page;
        })
        .catch(() => {})
        .finally(() => {
          if (this.orders == 0) {
            this.noOrder = true;
          } else {
            this.noOrder = false;
          }
        });
    },
    nextPage() {
      this.currentPage++;
      window.scrollTo(0, 0);
      this.getOrders();
    },

    previousPage() {
      this.currentPage--;
      window.scrollTo(0, 0);
      this.getOrders();
    },
  },
  created() {
    this.isAuthenticated = this.getAuthenticatedStatus;
    this.getOrders();
  },

  watch: {
    getAuthenticatedStatus() {
      this.isAuthenticated = this.getAuthenticatedStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  overflow: hidden;
  justify-content: center;
  display: flex;
  width: 75rem;

  .content {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    margin-right: 2rem;
    min-height: 30rem;

    .filter {
      display: flex;
      align-items: baseline;
      z-index: 10;
      flex-wrap: wrap;

      .calendar {
        width: 12.5rem;
      }

      .dropdown {
        margin-left: 1rem;
        display: flex;
        align-items: center;

        .icon-close {
          -webkit-text-stroke: 0.6px black;
          font-size: 0.813rem;
          margin-left: 1rem;
          cursor: pointer;
        }
      }

      .my-dropdown-toggle {
        border-radius: 0.3rem;
        padding: 0;

        ::v-deep .dropdown-toggle {
          color: #ff8b00;
          font-size: 1rem;
          font-weight: 300;
        }

        ::v-deep .dropdown-toggle-placeholder {
          color: #c4c4c4;
        }
      }
    }
  }

  .pagination-button-component {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 570px) {
  .container {
    .content {
      margin-right: 0;
      max-width: 22rem;
      h2 {
        text-align: center;
      }

      .filter {
        justify-content: flex-start;
        .dropdown {
          margin: 0;
        }
      }
    }
  }
}
</style>
