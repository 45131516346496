<template>
  <modal-component
    class="pdf-modal"
    :title="'Detalhes'"
    :showModal="true"
    @close="close"
  >
    <template v-slot:body>
      <div id="content" class="content">
        <div class="button-container" v-if="!isLoading">
          <button-component
            :isDisabled="sizePdf <= 25 || isLoading"
            class="button-component"
            :iconRigth="'icon-zoom-out'"
            text=""
            @click.native="rezisePdf('zoom-out')"
          />

          <h3>{{ sizePdf }}%</h3>
          <button-component
            :isDisabled="sizePdf >= 200 || isLoading"
            class="button-component"
            :iconRigth="'icon-zoom-in'"
            text=""
            @click.native="rezisePdf('zoom-in')"
          />
          <button-component
            class="button-component"
            :iconRigth="'icon-keyboard-arrow-down'"
            text=""
            @click.native="switchPage('previous')"
            :isDisabled="page < 2 || isLoading"
          />

          <h3>{{ page }} de {{ pageCount }}</h3>
          <button-component
            class="button-component"
            :iconRigth="'icon-keyboard-arrow-up'"
            text=""
            @click.native="switchPage('next')"
            :isDisabled="page >= pageCount || isLoading"
          />
        </div>

        <div class="spinner" v-if="isLoading">
          <spinner-component :styles="SpinStyle" />
        </div>

        <div class="pdf" :class="{ hide: isLoading }">
          <vue-pdf-embed
            v-if="!isRezise"
            :disableTextLayer="true"
            :disableAnnotationLayer="true"
            ref="pdfRef"
            :width="width"
            :page="page"
            :source="propSrc"
            @rendered="rende"
          />
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import Modal from "@/components/Modal.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import Button from "@/components/Button.vue";
import Spinner from "../components/Spinner.vue";

export default {
  name: "PdfModal",
  components: {
    "spinner-component": Spinner,
    "button-component": Button,
    "modal-component": Modal,
    VuePdfEmbed,
  },

  data() {
    return {
      isRezise: false,
      isLoading: true,
      width: 1000,
      page: 1,
      sizePdf: 100,
      percentageZoom: null,
      pageCount: 1,
      SpinStyle: {
        size: "10px",
        color: "#ff8b00",
        borderSize: "1.5em",
      },
    };
  },

  props: {
    propSrc: {
      type: String,
    },
  },

  mounted() {
    this.sizeView();
  },
  methods: {
    close() {
      this.$emit("close");
    },

    switchPage(value) {
      if (value == "next") {
        this.page++;
      } else {
        this.page--;
      }
    },

    rende() {
      this.pageCount = this.$refs.pdfRef.pageCount;
      this.isLoading = false;
    },

    sizeView() {
      let element = document.getElementById("content");
      this.width = element.clientWidth;
      this.percentageZoom = this.width * 0.25;
    },

    rezisePdf(value) {
      this.isLoading = true;
      if (value == "zoom-in") {
        this.sizePdf += 25;
        this.isRezise = true;
        this.width = this.width + this.percentageZoom;
        this.isRezise = false;
      } else {
        this.width = this.width - this.percentageZoom;
        this.sizePdf -= 25;
        this.isRezise = true;
        this.isRezise = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: fit-content;
  max-width: 80vw;
  min-width: 50vw;
  height: 80vh;
  background-color: #e2e0e0;

  .button-container {
    padding: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    width: 100%;

    .button-component {
      height: 2rem;
      width: 2rem;
      padding-right: 0.5rem;
      margin: 0 1rem;
    }
  }

  .spinner {
    display: flex;
    height: 80vh;
    align-items: center;
    justify-content: center;
  }

  .pdf {
    max-height: 91%;
    width: 100%;
    overflow: auto;

    &.hide {
      display: none;
    }
  }
}

@media (max-width: 650px) {
  .content {
    width: 100%;
    max-width: 100%;

    .button-container {
      max-width: 100%;
      .button-component {
        margin: 0 0.2rem;
      }
    }

    .pdf {
      max-height: 90%;
      width: 100%;
    }
  }
}
</style>
