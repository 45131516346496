<template>
  <default-view-component
    :hasHeader="false"
    :hasSearchInput="true"
    :hasFooter="pdfModal ? false : true"
  >
    <template v-slot:content>
      <div class="container" :class="{ fixed: pdfModal }">
        <div class="content">
          <h2>{{ item.nome }}</h2>

          <div class="item-detail">
            <div class="image-container">
              <spinner-component v-if="!isLoaded" />

              <img
                v-if="imgSrc != ''"
                v-show="isLoaded"
                :src="imgSrc"
                @load="loadedImg"
                @error="errorImg"
              />
              <div class="button-modal">
                <button-component
                  @click.native="open = true"
                  class="button-component"
                  text=""
                  :iconRigth="'icon-zoom-in'"
                />
                <image-modal
                  v-if="open"
                  @close="open = false"
                  :ImageProps="item"
                />
              </div>
            </div>

            <div class="summary-container">
              <div class="summary">
                <div class="item">
                  <h2 class="amount">{{ formatPrice(item.valor) }}</h2>
                </div>

                <div class="buttons-container">
                  <button-component
                    @click.native="buyNow(item.codigo)"
                    class="button-component full"
                    text="Adicionar ao carrinho"
                  />
                </div>
              </div>
              <div class="description">
                <div class="line"></div>
                <div class="description-title">
                  <h2>Descrição</h2>
                  <button-component
                    v-if="item.pdf_preview != null && item.pdf_preview != ''"
                    @click.native="pdfModal = true"
                    class="button-component full"
                    :iconRigth="'icon-document-file-pdf'"
                    text="Ver detalhes"
                  />
                </div>
                <div v-if="pdfModal" class="showModal">
                  <pdf-modal
                    @close="pdfModal = false"
                    :propSrc="item.pdf_preview"
                    :showModal="true"
                  />
                </div>
                <div v-if="item.descricao != '' && item.descricao != null">
                  <p
                    v-for="(description, index) in stringFormated"
                    :key="description"
                  >
                    {{ description
                    }}{{ index == stringFormated.length - 1 ? "" : "." }}
                  </p>
                </div>
                <div v-else>
                  <p>Item sem descrição.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content">
          <div>
            <h2>Itens Relacionados</h2>
            <div class="relatedItems">
              <div class="items">
                <item-card-component
                  class="item-card-component"
                  v-for="item in items"
                  :key="item.id"
                  :itemProps="item"
                  @itemClick="toItemDetail(item.codigo)"
                  :hasRequest="hasRequest"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </default-view-component>
</template>

<script>
import DefaultView from "@/components/Views/DefaultView";
import Button from "@/components/Button";
import { instance } from "../config/index";
import ItemCard from "@/components/ItemCard";
import ImageModal from "@/components/ImageModal";
import Spinner from "@/components/Spinner";
import PdfModal from "../components/PdfModal.vue";

export default {
  name: "ItemDetail",

  components: {
    "pdf-modal": PdfModal,
    "spinner-component": Spinner,
    "default-view-component": DefaultView,
    "button-component": Button,
    "item-card-component": ItemCard,
    "image-modal": ImageModal,
  },
  data() {
    return {
      imgSrc: "",
      hasImgError: false,
      isLoaded: false,
      pdfModal: false,
      open: false,
      item: {},
      stringFormated: null,
      items: null,
      price: null,
      count: 1,
    };
  },

  created() {
    window.scrollTo(0, 0);
    this.id = this.$route.query.item;
    this.getItem(this.id);
  },

  methods: {
    formatPrice(value) {
      return this.$service.priceFormatter(value);
    },

    hasImgFetch() {
      return !this.isLoaded;
    },

    loadedImg() {
      this.isLoaded = true;
    },

    errorImg() {
      this.hasImgError = true;
    },

    async getItem(id) {
      const data = { Codigo: id };

      await instance()
        .post("/ecommerce/product", data)
        .then((response) => {
          this.item = response;
          this.getItems();
          this.imgSrc = `https://dashboard.imnic.com.br/image.php?src=${this.item.imagem}&tam=gg`;
          this.formatString(this.item.descricao);
          this.formatPrice(this.item.valor);
        })
        .catch(() => {
          this.$router.push("/");
        });
    },

    async getItems() {
      this.hasRequest = true;

      const data = { limit_items: 4, subcategoria: this.item.subcategoria };
      await instance()
        .post("/ecommerce/products", data)
        .then((response) => {
          this.shuffleItems(response.total_pages);
        })
        .catch(() => {
          this.$router.push("/");
        });
      this.hasRequest = false;
    },

    buyNow(value) {
      let cart = JSON.parse(localStorage.getItem("itemCart"));
      const findItemCard = cart.find((i) => i.codigo === value);

      if (findItemCard) {
        findItemCard.qt++;
        localStorage.setItem("itemCart", JSON.stringify(cart));
        this.$router.push("/cart").catch(() => {});
      } else {
        cart.unshift({
          codigo: value,
          qt: this.count,
          nome: this.item.nome,
          imagem: this.item.imagem,
          valor: this.item.valor,
          peso: this.item.peso,
          comprimento: this.item.comprimento,
          largura: this.item.largura,
          altura: this.item.altura,
        });
        localStorage.setItem("itemCart", JSON.stringify(cart));
        this.$router.push("/cart").catch(() => {});
      }
    },

    toItemDetail(itemId) {
      this.$router.push({
        name: "ItemDetail",
        query: {
          item: itemId,
        },
      });
      window.location.reload();
    },

    itemClick() {
      if (!this.isDisabled) {
        this.$emit("itemClick");
      }
    },

    formatString(string) {
      if (string != null) {
        const word = string.split(".");
        this.stringFormated = word;
      }
    },

    shuffleItems(page) {
      var randomPage = Math.floor(Math.random() * page);

      const data = {
        limit_items: 4,
        current_page: randomPage,
        subcategoria: this.item.subcategoria,
      };
      instance()
        .post("/ecommerce/products", data)
        .then((response) => {
          const data = response.items;
          this.items = data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 80rem;

  &.fixed {
    position: fixed;
  }

  .content {
    box-shadow: rgb(40 41 61 / 2%) 0 0.25rem 0.5rem,
      rgb(96 97 112 / 10%) 0 0.5rem 1rem;
    border-radius: 0.3rem;
    width: 100%;
    height: fit-content;
    background: #fff;
    padding: 1rem;
    margin-bottom: 2rem;

    .item-detail {
      margin-top: 1rem;
      display: flex;

      .image-container {
        border-radius: 0.3rem;
        position: relative;
        width: 26rem;
        height: 26rem;
        background: #f2f2f2;
        margin-right: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;

        .button-modal {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
          height: 1rem;

          .button-component {
            height: 2rem;
            width: 2rem;
            padding-right: 0.5rem;
          }
        }

        img {
          max-height: 100%;
          max-width: 100%;
        }
      }

      .summary-container {
        width: 60%;
        padding: 0 1rem 1rem 0;
        height: fit-content;
        border-radius: 0.3rem;

        .summary {
          display: flex;
          align-items: center;

          .item {
            display: flex;

            p {
              &.amount {
                margin-left: 0.5rem;
                font-weight: 500;
              }
            }
          }
        }

        .buttons-container {
          margin: 0 0 0 1rem;
          display: flex;
          justify-content: space-around;

          .button-component {
            max-width: 100%;
            height: 2.5rem;
          }
        }
      }
      .description {
        text-align: justify;

        .description-title {
          display: flex;
          align-items: center;
          .button-component {
            height: 2.5rem;
            width: 10rem;
            padding-right: 0.5rem;
            margin: 0 1rem;
          }
        }

        p {
          text-transform: lowercase;
        }

        p:first-letter {
          text-transform: uppercase;
        }
      }
    }

    .line {
      margin: 1rem 0;
      height: 0.01rem;
      width: 100%;
      background: #dadada;
    }

    .relatedItems {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: #f8f8f8;
      border-radius: 0.3rem;

      .items {
        width: 100%;
        padding: 1rem;
        display: grid;
        row-gap: 2rem;
        grid-template-columns: repeat(4, 1fr);
        justify-items: center;
      }
    }
  }
}

@media (max-width: 980px) {
  .container {
    max-width: 100%;
    .content {
      .relatedItems {
        .items {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 835px) {
  .container {
    .content {
      margin: 0;
      h2 {
        text-align: center;
      }
      .item-detail {
        flex-wrap: wrap;
        justify-content: center;
        .image-container {
          max-width: 100%;
          margin-right: 0;
        }
        .summary-container {
          width: 100%;
          min-height: fit-content;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1rem;
    .content {
      margin: 1rem 0;
      .item-detail {
        .image-container {
          max-width: 100%;
          justify-content: center;
          justify-items: center;
          display: flex;
          align-items: center;
        }
        .summary-container {
          margin-top: 1rem;
          padding: 0;

          .summary {
            justify-content: space-between;
          }

          .buttons-container {
            .button-component {
              height: 5rem;
            }
          }
        }
        .description {
          .description-title {
            justify-content: center;
            flex-wrap: wrap;

            .button-component {
              width: 100%;
              margin: 1rem;
            }
          }
        }
      }

      .relatedItems {
        .items {
          grid-template-columns: 1fr;
          padding: 1rem 0;
          .item-card-component {
            width: 18rem;
          }
        }
      }
    }
  }
}
</style>
