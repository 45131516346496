<template>
  <transition name="fade">
    <div
      class="modal animate__animated animate__fadeIn animate__faster"
      v-if="showModal"
      :class="{ top: isTopOnMobile }"
    >
      <div class="dialog">
        <div class="content">
          <div class="icon">
            <slot name="icon" />
          </div>

          <div class="body-container">
            <div v-if="title" class="header-container">
              <h2 >{{ title }}</h2>
              <i class="icon-close" @click="close" />
            </div>
            <slot name="body" />
          </div>
        </div>

        <div class="footer-container">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",

  props: {
    data: {
      type: Object,
      default() {
        return {
          addressId: {},
        };
      },
    },
    addressInfo: {
      type: Object,
    },
    showModal: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      required: false,
    },

    isTopOnMobile: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit("close");
    },
    itemClick() {
      if (!this.isDisabled) {
        this.$emit("itemClick");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modal {
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  z-index: 15;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  justify-content: center;
  align-items: center;
  display: flex;

  .dialog {
    background: #fff;
    box-shadow: 0 0 1.8rem rgba(0, 0, 0, 0.15);
    border-radius: 0.3rem;
    min-width: 35rem;
    z-index: 1;

    .content {
      .icon {
        float: right;
        color: #979797;
        cursor: pointer;
      }

      .header-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;

        h2 {
          color: #333;
          white-space: nowrap;
          position: absolute;
        }

        i {
          color: #979797;
          padding: 0.5rem;
          cursor: pointer;
          margin-left: auto;

          &:hover {
            border-radius: 100%;
            background: #e4e4e4;
          }
        }
      }

      .body-container {
        color: #333;
        padding: 1.5rem;

        .input-container {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 20%;
          .buttons-container {
            min-height: 2rem;
            padding-left: 15%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .modal {
    justify-content: left;

    &.top {
      .dialog {
        top: 0;
        bottom: unset;
        border-radius: 0 0 0.3rem 0.3rem;
      }
    }

    .dialog {
      position: fixed;
      max-height: 100vh;
      min-width: 20rem;
      width: 100%;
      border-radius: 0.3rem;
    }
  }
}

@media (max-width: 480px) {
  .modal {
    .dialog {
      .content {
        .body-container {
          .header-container {
            h2 {
              font-size: 1rem;
            }
          }

          padding: 1rem;
        }
      }
    }
  }
}
</style>
