<template>
  <modal-component
    class="orders-modal"
    :title="'Detalhes do Pedido: #' + ordersInfo.codigo"
    :showModal="true"
    @close="close"
  >
    <template v-slot:body>
      <div class="content">
        <div class="purchase">
          <div>
            <p>Responsável: {{ ordersInfo.responsavel }}</p>
            <p>
              Realizado: {{ ordersInfo.data_cadastro_formatada }} às
              {{ ordersInfo.hora_cadastro_formatada }}
            </p>
          </div>
          <div>
            <h3 v-if="ordersInfo.cod_rastreio != ''">
              Código de Rastreio: {{ ordersInfo.cod_rastreio }}
            </h3>
            <div class="status">
              <p>Status:</p>
              <p v-bind:class="getOrder()">
                {{ ordersInfo.status }}
                <i v-bind:class="iconClass" />
              </p>
            </div>
          </div>
        </div>

        <div v-if="isMobile" class="products-details">
          <h3>Cod.</h3>
          <h3>Val.</h3>
          <h3>Quant.</h3>
          <h3>SubTotal</h3>
        </div>

        <div v-else class="products-details">
          <h3>Codigo</h3>
          <h3>Produto</h3>
          <h3>Quantidade</h3>
          <h3>Valor</h3>
          <h3>SubTotal</h3>
        </div>

        <div class="products-container">
          <div class="products" v-for="details in details" :key="details.key">
            <p class="cod-product">{{ details.codigo_produto }}</p>
            <p class="name-product">{{ details.nome }}</p>
            <p class="qt-product">{{ details.quantidade }}</p>
            <p class="value-product">{{ priceFormatter(details.valor) }}</p>
            <p class="subtotal-product">
              {{
                priceFormatter(
                  parseFloat(details.valor) * parseFloat(details.quantidade)
                )
              }}
            </p>
          </div>
        </div>

        <div class="values-container">
          <p>ST: {{ priceFormatter(values.valor_st) }}</p>
          <p>ICMS: {{ priceFormatter(values.valor_icms) }}</p>
          <p>Frete: {{ priceFormatter(values.valor_frete) }}</p>
          <h2>Total: {{ priceFormatter(values.total) }}</h2>

          <button-component
            v-if="ordersInfo.status == 'Aguardando Pagto'"
            class="button-component"
            text="Efetuar Pagamento"
            @click.native="checkout"
          />
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import instance from "../config";

export default {
  components: {
    "button-component": Button,
    "modal-component": Modal,
  },

  data() {
    return {
      isMobile: false,
      details: {},
      values: {},
    };
  },

  props: {
    ordersInfo: {
      type: Object,
    },
  },

  created() {
    this.getOrderDetails();
    this.getScreenSize();
  },

  mounted() {
    window.addEventListener("resize", this.getScreenSize);
  },

  methods: {
    close() {
      this.$emit("close");
    },
    async checkout() {
      const data = { order_id: this.ordersInfo.codigo };

      await instance()
        .post("/ecommerce/createcheckout", data)
        .then((response) => {
          const checkout = response.url.split("checkout/");
          this.$router.push({
            name: "Payment",
            query: {
              checkoutId: checkout[1],
            },
          });
        })
        .catch(() => {});
    },

    getOrder() {
      if (this.ordersInfo.status == "Cancelado") {
        this.iconClass = "icon-close";
        return "cancell";
      }
      if (this.ordersInfo.status == "Despachado") {
        this.iconClass = "icon-truck";
        return "dispatch";
      }
      if (this.ordersInfo.status == "Em produção") {
        this.iconClass = "icon-configure";
        return "production";
      }
      if (this.ordersInfo.status == "Pendência Financeira") {
        this.iconClass = "icon-deposit-error";
        return "pending";
      }
      if (this.ordersInfo.status == "Aguardando Pagto") {
        this.iconClass = "icon-deposits";
        return "awaiting";
      } else {
        this.iconClass = "icon-spinner";
        return "review";
      }
    },

    priceFormatter(value) {
      return this.$service.priceFormatter(value);
    },

    async getOrderDetails() {
      const data = {
        Codigo: this.ordersInfo.codigo,
      };

      await instance()
        .post("/ecommerce/orderdetails", data)
        .then((response) => {
          this.values = response.details;
          this.details = response.details.products;
        })
        .catch(() => {});
    },
    getScreenSize() {
      if (window.screen.width <= 500) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  max-height: 80vh;
  width: 90vw;

  .status {
    display: flex;

    p {
      margin-right: 0.6rem;
    }

    & p.review {
      color: #ff8b00;
    }

    & p.production {
      color: #ff8b00;
    }

    & p.pending {
      color: #aa1428;
    }

    & p.awaiting {
      color: #ff8b00;
    }

    & p.dispatch {
      color: #008a0b;
    }

    & p.cancell {
      color: #aa1428;
    }
  }
  .purchase {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .products-details {
    font-weight: 300;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
    justify-items: start;
    margin-top: 1.5rem;
  }

  .products-container {
    max-height: 17rem;
    overflow-y: auto;
    margin: 0.6rem 0 1rem 0;

    .products:nth-child(odd) {
      background: #ddd;
    }

    .products {
      background: #f8f8f8;
      justify-items: start;
      font-weight: 300;
      display: grid;
      grid-template-columns: 1fr 3fr 1fr 1fr 1fr;
      padding: 0.5rem 0 0.5rem 0.5rem;

      :nth-child(odd) {
        font-weight: 700;
      }
    }
  }

  .values-container {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    p,
    h2 {
      margin-left: 1rem;
    }
    .button-component {
      max-width: 15rem;
      margin-left: 1rem;
      padding: 1rem;
    }
  }
}

@media (max-width: 650px) {
  .content {
    .purchase {
      grid-template-columns: 1fr;
    }
    .products-details {
      grid-template-columns: repeat(4, 1fr);
    }
    .products-container {
      .products {
        grid-template-columns: repeat(4, 1fr);
        .name-product {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-column-end: 5;
        }

        .subtotal-product {
          grid-column-start: 4;
        }

        .qt-product {
          grid-column-start: 3;
          justify-items: center;
        }
        .value-product {
          grid-row-start: 1;
          grid-column-start: 2;
        }
      }
    }
  }
}

iframe {
  height: 60vh;
  width: 90vw;
}
</style>
