<template>
  <default-view-component :hasHeader="false">
    <template v-slot:content>
      <div class="container">
        <div class="content">
          <h2>Meu endereço</h2>
          <div class="card-items">
            <address-card-component
              class="address-container"
              :addressProps="address"
              @itemClick="refreshPage()"
            />
          </div>
        </div>
      </div>
    </template>
  </default-view-component>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultView from "@/components/Views/DefaultView";
import AddressCard from "@/components/AddressCard";
import instance from "../config";
import Vue from "vue";

export default {
  name: "Address",

  data() {
    return {
      valor: 300,
      frete: 10,
      count: 1,
      isAuthenticated: false,
      address: {},
      open: false,
    };
  },
  methods: {
    async getAddress() {
      instance()
        .post("user/me", {
          codigo_usuario: Vue.cookie.get("codigoUser"),
        })
        .then((response) => {
          const data = response;
          this.address = data;
        })
        .catch(() => {});
    },

    refreshPage() {
      this.getAddress();
    },

    openModal() {},
  },

  computed: {
    ...mapGetters(["getAuthenticatedStatus"]),
  },

  components: {
    "default-view-component": DefaultView,
    "address-card-component": AddressCard,
  },

  created() {
    this.isAuthenticated = this.getAuthenticatedStatus;
    this.getAddress();
  },

  watch: {
    getAuthenticatedStatus() {
      this.isAuthenticated = this.getAuthenticatedStatus;
    },
  },
  mounted() {
    this.getAddress();
  },
};
</script>

<style lang="scss" scoped>
.container {
  justify-content: center;
  display: flex;
  padding: 2rem;
  width: 75rem;

  .content {
    box-shadow: rgb(40 41 61 / 2%) 0 0.25rem 0.5rem,
      rgb(96 97 112 / 10%) 0 0.5rem 1rem;
    border-radius: 0.3rem;
    width: 100%;
    background: #fff;
    padding: 1rem;
    margin-right: 2rem;

    .card-items {
      margin-top: 1rem;
      display: grid;
      grid-template-columns: 1fr;

      .address-content {
        padding-left: 1rem;
        margin-left: 0.6rem;
        background: #efefef;
        width: fit-content;

        ul {
          list-style-type: none;
        }

        &:hover {
          box-shadow: rgb(40 41 61 / 4%) 0 0.25rem 0.5rem,
            rgb(96 97 112 / 16%) 0 0.5rem 1rem;
        }
      }
    }
  }
}

@media (max-width: 1140px) {
  .container {
    .content {
      h2 {
        text-align: center;
      }
      .cart-items {
        grid-template-columns: 1fr;
        justify-items: center;
      }
      .address-content {
        margin-top: 1rem;
      }
      .new-address {
        text-align: center;
      }
    }
  }
}

@media (max-width: 570px) {
  .container {
    .content {
      max-width: 22rem;
      margin: 0;

      h2 {
        text-align: center;
      }

      .cart-items {
        grid-template-columns: 1fr;

        .address-content {
          padding-left: 0.3rem;
          margin-left: 0;
          margin-top: 1rem;
          width: 20.5rem;
        }
      }
    }
  }
}
</style>
