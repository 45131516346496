<template>
  <div
    class="item-card-container"
    :class="{ disable: hasRequest }"
    @click="itemClick"
  >
    <div class="image-container">
      <spinner v-if="hasImgFetch()" />
      <img
        v-show="!hasImgFetch() && !hasImgError"
        :src="`https://dashboard.imnic.com.br/image.php?src=${itemProps.imagem}&tam=gb`"
        @load="loadedImg"
        @error="errorImg"
        loading="auto"
      />
    </div>

    <h3 class="title">{{ itemProps.nome }}</h3>

    <div class="price-container">
      <div
        v-if="hasImgFetch() && itemProps.imagem != null"
        class="shimmer"
      ></div>
      <h2 v-else class="price">
        {{ formatPrice(itemProps.valor) }}
      </h2>
    </div>

    <div class="button-container" @click="toCart(itemProps.codigo)">
      <i class="icon-cart" />
      Comprar
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";

export default {
  name: "ItemCard",

  components: {
    Spinner,
  },

  data() {
    return {
      isLoaded: false,
      hasImgError: false,
    };
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          item: {},
        };
      },
    },

    hasRequest: {
      type: Boolean,
      default: false,
    },

    itemProps: {
      type: Object,
    },

    showPrice: {
      type: Boolean,
      default: true,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    formatPrice(value) {
      return this.$service.priceFormatter(value);
    },

    itemClick() {
      if (!this.isDisabled) {
        this.$emit("itemClick");
      }
    },
    toCart(value) {
      let cart = JSON.parse(localStorage.getItem("itemCart"));

      const findItemCard = cart.find((i) => i.codigo === value);

      if (findItemCard) {
        findItemCard.qt++;
        localStorage.setItem("itemCart", JSON.stringify(cart));
        this.$router.push("/cart").catch(() => {});
      } else {
        cart.unshift({
          codigo: value,
          qt: 1,
          nome: this.itemProps.nome,
          imagem: this.itemProps.imagem,
          valor: this.itemProps.valor,
          peso: this.itemProps.peso,
          comprimento: this.itemProps.comprimento,
          largura: this.itemProps.largura,
          altura: this.itemProps.altura,
        });
        localStorage.setItem("itemCart", JSON.stringify(cart));
        this.$router.push("/cart").catch(() => {});
      }
    },
    hasImgFetch() {
      return !this.isLoaded || this.hasRequest;
    },

    loadedImg() {
      this.isLoaded = true;
    },

    errorImg() {
      this.hasImgError = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-card-container {
  position: relative;
  width: 16rem;
  min-height: 25rem;
  color: #333;
  cursor: pointer;
  box-shadow: rgb(40 41 61 / 2%) 0 0.25rem 0.5rem,
    rgb(96 97 112 / 4%) 0 0.5rem 1rem;
  background: #fff;
  padding: 0.5rem;
  transition: 0.2s box-shadow ease-in-out;
  border-radius: 0.3rem;
  &:hover {
    box-shadow: rgb(40 41 61 / 4%) 0 0.25rem 0.5rem,
      rgb(96 97 112 / 16%) 0 0.5rem 1rem;
  }

  &.disable {
    cursor: not-allowed;
    opacity: 0.6;

    .image-container {
      img {
        display: none;
      }
    }
    .price-container {
      max-width: 50%;
      h2 {
        display: none;
      }
    }
    h3 {
      display: none;
    }
  }

  .shimmer {
    background: linear-gradient(-60deg, #eeeeee, #c9c9c9, #eeeeee);
    width: 100%;
    border-radius: 0.3rem;
    background-size: 400% 400%;
    animation: shimmer 3s ease infinite;
    height: 2rem;
  }

  .image-container {
    height: 12.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    background: #efefef;
    border-radius: 0.3rem;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  h3 {
    padding: 0.5rem 0;
    min-height: 3rem;
    color: #696969;
  }

  .price-container {
    position: absolute;
    bottom: 2.7rem;
    height: 2.5rem;
    display: flex;
    align-items: center;

    h2 {
      color: #333;
    }
  }

  .button-container {
    position: absolute;
    width: 93%;
    bottom: 0.5rem;
    padding: 0.5rem;
    font-size: 0.875rem;
    text-align: center;
    font-weight: 500;
    background: #ff9c00;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5rem;
    transition: 0.3s;
    border-radius: 0.3rem;

    i {
      margin-right: 0.5rem;
    }

    &:hover {
      background: #e68d00;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 480px) {
  .item-card-container {
    .image-container {
      height: 12.5rem;
    }
  }
}
</style>
