<template>
  <default-view-component :hasHeader="false" :hasFooter="false">
    <template v-slot:content>
      <div class="container">
        <iframe
          :class="{ hide: !load }"
          :src="`https://checkout.impay.com.br/ecommerce/checkout/${checkoutId}?hideNavbar=true&hideFooter=true`"
          frameborder="0"
        ></iframe>
      </div>
      <div class="loading" v-if="!load">
        <spinner :styles="SpinStyle" />
      </div>
    </template>
  </default-view-component>
</template>

<script>
import DefaultView from "@/components/Views/DefaultView";
import Spinner from "@/components/Spinner";

export default {
  name: "Payment",

  components: {
    Spinner,
    "default-view-component": DefaultView,
  },

  data() {
    return {
      checkoutId: "",
      load: false,
      SpinStyle: {
        size: "0.6rem",
        color: "#ff8b00",
        borderSize: "1.5em",
      },
    };
  },

  created() {
    this.checkoutId = this.$route.query.checkoutId;
  },

  mounted() {
    window.addEventListener("message", this.receiveMessage);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage);
  },

  methods: {
    receiveMessage(event) {
      if (event.data == "impay-checkout-loaded") {
        this.load = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  width: 100%;
  height: 90vh;

  iframe {
    &.hide {
      visibility: hidden;
    }
    width: 100%;
    height: 100%;
  }
}

.loading {
  display: flex;
  height: 80vh;
  align-items: center;
}
</style>
