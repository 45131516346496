<template>
  <div class="address-content">
    <ul>
      <li>Nome: {{ addressProps.nome }}</li>
      <li>Telefone: {{ formaterNumberCell(addressProps.celular) }}</li>
      <li>CEP: {{ formaterZipCode(addressProps.cep) }}</li>
      <li>
        Endereço: {{ addressProps.nome_cidade }}, {{ addressProps.bairro }}
      </li>
      <li>Número: {{ addressProps.nro }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "AddressCard",
  data() {
    return {
      isAuthenticated: false,
      open: false,
      nome: "Nome",
      endereco: "Endereco",
      cep: "CEP",
      telefone: "telefone",
      numero: "Número residência",
    };
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          addr: {},
        };
      },
    },
    addressProps: {
      type: Object,
    },
  },

  methods: {
    itemClick() {
      if (!this.isDisabled) {
        this.$emit("itemClick");
      }
    },
    close() {
      this.open = false;
    },

    formaterZipCode(value) {
      if (value != null && value != undefined) {
        value = value.replace(/\D/g, "");
        value = value.replace(/^(\d{5})(\d)/, "$1-$2");
        return value;
      }
    },

    formaterNumberCell(value) {
      if (value != null && value != undefined) {
        value = value.replace(/\D/g, "");
        value = value.replace(/^(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
        return value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.address-content {
  border-radius: 0.3rem;
  margin-top: 0.5rem;
  padding: 1rem;
  margin-left: 0.5rem;
  background: #efefef;

  ul {
    list-style-type: none;
  }

  &:hover {
    box-shadow: rgb(40 41 61 / 4%) 0 0.25rem 0.5rem,
      rgb(96 97 112 / 16%) 0 0.5rem 1rem;
  }
}

.buttons-container {
  .button-component {
    min-height: 2rem;
    max-width: 8rem;
    font-size: 1rem;
  }
}

@media (max-width: 570px) {
  .address-content {
    margin-top: 1rem;
  }
  .buttons-container {
    .button-component {
      max-width: 7rem;
      min-height: 2rem;
      align-items: center;
    }
  }
}
</style>
