<template>
  <modal-component
    class="purchase-modal"
    :title="'Finalizar Pedido'"
    :showModal="true"
    @close="close"
  >
    <template v-slot:body>
      <ValidationObserver tag="div" class="content" v-slot="{ passes }">
        <div class="franchise">
          <p>Franquia: {{ user }}</p>
        </div>
        <div class="select">
          <select-field
            label="Método de pagamento"
            rules="required"
            :option="[
              { label: 'Boleto', value: 'boleto' },
              { label: 'Pix ou Cartão', value: 'pagseguro' },
            ]"
            @input="selectPayment"
          />
        </div>
        <div class="input">
          <ValidationProvider rules="required" ref="input">
            <input-field
              rules="required"
              v-model="worker"
              class="inputField"
              :maxLength="40"
              placeholder="Funcionário para Contato"
            />
          </ValidationProvider>
        </div>
        <div class="value">
          <h3>Total: {{ AmountProps }}</h3>
        </div>
        <div class="button-container" @click.prevent="passes(toPurchaseDetail)">
          <button-component class="button-component" text="Concluir" />
        </div>
      </ValidationObserver>
    </template>
  </modal-component>
</template>

<script>
import ModalVue from "./Modal.vue";
import InputField from "./InputField.vue";
import Button from "./Button.vue";
import Vue from "vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import SelectField from "./SelectField.vue";

export default {
  name: "puchaseModal",

  data() {
    return {
      user: Vue.cookie.get("user"),
      worker: "",
      items: this.purchaseDetail,
      amount: this.AmountProps,
      payment_type: "",
    };
  },
  components: {
    SelectField,
    ValidationProvider,
    ValidationObserver,
    "modal-component": ModalVue,
    InputField,
    "button-component": Button,
  },

  props: {
    purchaseDetail: {
      type: Object,
    },
    AmountProps: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    selectPayment(value) {
      this.payment_type = value;
    },

    toPurchaseDetail() {
      sessionStorage.setItem(
        "purchase",
        JSON.stringify({
          payment_type: this.payment_type,
          items: this.items,
          valor: this.amount,
          funcionario: this.worker,
          franquia: this.user,
        })
      );
      this.$router.push("/purchase/detail").catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  .franchise,
  .input {
    margin: 1rem 0;
  }

  .button-container {
    .button-component {
      height: 3rem;
    }
  }
}
</style>
