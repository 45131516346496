export default {
  isNull(value) {
    return value === null;
  },

  isUndefined(value) {
    return value === undefined;
  },

  toUpperCase(str) {
    return str.toUpperCase();
  },

  isEmptyObject(object) {
    return !!Object.values(object).length == 0;
  },

  splitFirstName(str) {
    let splitStringArray = str.split(" ");
    return splitStringArray[0];
  },

  splitLastName(str) {
    let splitStringArray = str.split(" ");
    return splitStringArray.length > 1
      ? splitStringArray[splitStringArray.length - 1]
      : "";
  },
  priceFormatter(value) {
    if (value != null) {
      let formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
      });
      return formatter.format(parseFloat(value));
    }
  },
  zipcodeFormatter(value) {
    if (value != null) {
      let zipcode = value;
      zipcode = zipcode.replace(/\D/g, "");

      return zipcode.replace(/^(\d{5})(\d)/, "$1-$2");
    }
  },
};
