import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; // Importe o idioma que desejar
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

// Defina o idioma padrão
dayjs.locale('pt-br');
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);

// Crie o objeto do plugin
const dayjsPlugin = {
  install(Vue) {
    Vue.prototype.$dayjs = dayjs;
  },
};

// Instale o plugin no Vue
Vue.use(dayjsPlugin);

export default dayjs;
