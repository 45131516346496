<template>
  <ValidationProvider
    tag="div"
    :vid="vid"
    :name="name"
    :rules="rules"
    class="select-container"
    v-slot="{ errors, required }"
  >
    <div>
      <p>
        {{ label }}

        <span>
          {{ required ? "*" : "" }}
        </span>
      </p>
    </div>

    <div class="select-container">
      <select v-model="innerValue" :class="{ error: !!errors[0] }">
        <option hidden selected value="">Selecione</option>

        <option
          v-for="(item, index) in option"
          :value="item.value"
          :key="index"
        >
          {{ item.label }}
        </option>
      </select>

      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "SelectField",

  props: {
    label: {
      type: String,
      default: "",
    },

    vid: {
      type: String,
      default: undefined,
    },

    rules: {
      type: [Object, String],
      default: "",
    },

    option: {
      type: Array,
    },
  },

  components: {
    ValidationProvider,
  },

  data() {
    return {
      name: null,
      open: false,
      innerValue: "",
    };
  },

  watch: {
    innerValue(value) {
      this.$emit("input", value);
    },
  },

  mounted() {
    if (this.refName) {
      this.name = this.refName;
    } else {
      this.name = this.label.toLowerCase();
    }
  },
};
</script>

<style lang="scss" scoped>
.select-container {
  width: 100%;

  p {
    margin-bottom: 0.5rem;
  }

  span {
    color: #b93a5b;
  }

  .select-container {
    select {
      height: 3rem;
      color: #333;
      border: 0.05rem solid #dadada;
      align-items: center;
      font-size: 0.875rem;
      display: flex;
      border-radius: 0.3rem;
      padding: 0 1rem;
      cursor: pointer;
      user-select: none;
      width: 100%;
      background: url("data:image/svg+xml,<svg height='0.6rem' width='0.6rem' viewBox='0 0 16 16' fill='%23333333' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
        no-repeat #fff;
      background-position: calc(100% - 0.75rem) center !important;
      -moz-appearance: none !important;
      -webkit-appearance: none !important;
      appearance: none !important;
      padding-right: 2rem !important;

      &.error {
        border: 0.05 solid #b93a5b;
      }
    }

    span {
      color: #b93a5b;
      font-size: 0.75rem;
      margin-top: 0.2rem;
      display: flex;
      min-width: 12rem;
    }
  }
}
</style>
