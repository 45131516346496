<template>
  <default-view-component
    :hasHeader="true"
    :hasSearchInput="true"
    @filterCategory="filterCategory()"
    @search="search()"
  >
    <template v-slot:content>
      <div class="container">
        <!-- <div v-if="isAuthenticated" class="section"> -->
        <!-- <slide-component class="slide-component" /> -->
        <!-- </div> -->
        <div v-if="!noItems" class="section">
          <div class="items" v-if="renderVfor">
            <item-card-component
              class="item-card-component"
              v-for="item in items"
              :key="item.id"
              :itemProps="item"
              @itemClick="toItemDetail(item.codigo)"
              :hasRequest="hasRequest"
            />
          </div>
        </div>
        <div class="section" v-else-if="noItems">
          <h1>Produto não encontrado</h1>
        </div>
        <pagination-button-component
          v-if="isMobile && pageLength > 1 && !noItems"
          class="pagination-button-component"
          :pageProps="pageLength"
          :currentPage="parseInt(currentPage) - 1"
          @nextPage="switchPage($event)"
          @previousPage="switchPage($event)"
        />
        <paginate
          v-if="!isMobile && pageLength > 1 && !noItems"
          :modelValue="parseInt(currentPage)"
          :page-count="pageLength"
          :page-range="5"
          :margin-pages="2"
          :click-handler="switchPage"
          :prev-text="'Anterior'"
          :next-text="'Próxima'"
        >
        </paginate>
      </div>
    </template>
  </default-view-component>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultView from "@/components/Views/DefaultView";
import ItemCard from "@/components/ItemCard";
import Paginate from "../components/Pagination.vue";
import PaginationButton from "@/components/PaginationButton";
// import Slide from "@/components/Slide";
import Vue from "vue";
import { instance } from "../config/index";
import VueCookie from "vue-cookie";
import "animate.css";

Vue.use(VueCookie);

export default {
  name: "Markeplace",

  data() {
    return {
      isMobile: false,
      url: this.$route.fullPath,
      renderVfor: true,
      hasRequest: false,
      isAuthenticated: false,
      title: null,
      price: null,
      noItems: false,
      items: {},
      pageLength: null,
      currentPage: 1,
      query_page: "",
    };
  },

  computed: {
    ...mapGetters(["getAuthenticatedStatus"]),
  },

  components: {
    "default-view-component": DefaultView,
    "item-card-component": ItemCard,
    // "slide-component": Slide,
    "pagination-button-component": PaginationButton,
    paginate: Paginate,
  },

  created() {
    this.getSizePage();
    this.isAuthenticated = this.getAuthenticatedStatus;
  },

  mounted() {
    this.getItems();
  },

  watch: {
    getAuthenticatedStatus() {
      this.isAuthenticated = this.getAuthenticatedStatus;
    },

    $route(newValue, oldValue) {
      if (newValue.query != oldValue.query) {
        this.reRender();
        this.getItems();
      }
    },
  },

  methods: {
    async getItems() {
      this.hasRequest = true;
      const data = {
        busca: this.$route.query.search,
        limit_items: 20,
        subcategoria: this.$route.query.category,
        current_page: parseInt(this.$route.query.page_number - 1),
      };

      await instance()
        .post("/ecommerce/products", data)
        .then((response) => {
          const data = response.items;
          this.items = data;
          this.pageLength = response.total_pages;
          this.currentPage = parseInt(response.current_page + 1);
        })
        .catch(() => {})
        .finally(() => {
          if (this.items == 0) {
            this.noItems = true;
          } else {
            this.noItems = false;
          }
        });
      this.hasRequest = false;
    },

    reRender() {
      this.renderVfor = false;
      this.$nextTick(() => {
        this.renderVfor = true;
      });
    },

    getSizePage() {
      this.isMobile = window.screen.width < 700 ? true : false;
    },

    switchPage(value) {
      this.url = this.$route.fullPath;
      this.$router.push({
        path: this.url,
        query: {
          page_number: value,
        },
      });

      window.scroll(0, 0);
    },

    toItemDetail(itemId) {
      this.$router.push({
        name: "ItemDetail",
        query: {
          item: itemId,
        },
      });
    },

    filterCategory() {
      this.getItems();
    },

    search() {
      this.$store.state.filterCategory = "";
      this.getItems();
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .section {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 100rem;
    h1 {
      color: #333;
    }

    .items {
      display: grid;
      row-gap: 4rem;
      column-gap: 3rem;
      margin: 1.5rem 4rem 1.5rem 4rem;
      grid-template-columns: repeat(4, 1fr);
    }

    .carousel-component {
      padding: 1.5rem;
    }
  }

  .pagination-button-component {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 1215px) {
  .container {
    .section {
      .items {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media (max-width: 915px) {
  .container {
    .section {
      .items {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media (max-width: 625px) {
  .container {
    .section {
      .items {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}

@media (max-width: 480px) {
  .container {
    .section {
      .items {
        row-gap: 2rem;
        .item-card-component {
          width: 22rem;
        }
      }
    }
  }
}
</style>
