<template>
  <image-layout-component>
    <template v-slot:content>
      <div class="content">
        <ValidationObserver
          tag="div"
          class="form-login"
          v-slot="{ passes }"
          ref="form"
        >
          <form @submit.prevent="passes(setLogin)">
            <ValidationProvider rules="required" ref="input">
              <input-field-component
                rules="required"
                class="input-field-component"
                placeholder="Login"
                :name="'username'"
                v-model="login"
              />
            </ValidationProvider>
            <input-field-component
              @LoginEnter="passes(setLogin)"
              rules="required"
              class="input-field-component"
              placeholder="Senha"
              type="password"
              v-model="password"
              :isDisabled="loader"
            />
            <div class="button-container">
              <button-component
                type="submit"
                class="button-component"
                text="Login"
                :hasLoader="loader"
                :isDisabled="loader"
              />
            </div>
          </form>
        </ValidationObserver>
      </div>
    </template>
  </image-layout-component>
</template>

<script>
import Button from "@/components/Button";
import InputField from "@/components/InputField";
import ImageLayout from "@/components/Base/ImageLayout";
import Vue from "vue";
import VueCookie from "vue-cookie";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mapGetters } from "vuex";

Vue.use(VueCookie);

export default {
  name: "login",
  components: {
    ValidationProvider,
    ValidationObserver,
    "button-component": Button,
    "input-field-component": InputField,
    "image-layout-component": ImageLayout,
  },

  data() {
    return {
      loader: false,
      error: null,
      login: null,
      password: null,
      isAuthenticated: false,
    };
  },

  computed: {
    ...mapGetters(["getAuthenticatedStatus"]),
  },
  created() {
    this.isAuthenticated = this.getAuthenticatedStatus;
    if (this.isAuthenticated) {
      this.$router.push("/");
    }
  },

  methods: {
    setLogin() {
      this.loader = true;

      const data = {
        login: this.login.trim(),
        password: this.password.trim(),
      };

      localStorage.setItem("itemCart", JSON.stringify([]));
      this.$store.commit("setUserData", data);
      this.$store.dispatch("postLogin").then((response) => {
        if (response == "success") {
          const preOrderId = localStorage.getItem("preOrderId");
          if (preOrderId) {
            this.$store.commit("setPreOrderId", preOrderId);
            this.$router.push("/cart");
          } else {
            this.$router.push("/");
          }
        } else {
          this.$refs.form.setErrors({
            username: "Usuário ou senha inválido",
          });
          this.loader = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  max-width: 25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.form-login {
  width: 100%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;

  form {
    width: 100%;
  }

  .button-container {
    width: 100%;
    .button-component {
      height: 2.5rem;
    }
  }
}
</style>
