<template>
  <modal-component
    class="image-modal"
    title="Detalhes"
    :showModal="true"
    @close="close"
  >
    <template v-slot:body>
      <div class="content">
        <div class="image-container">
          <spinner-component v-if="!isLoaded" />
          <img
            v-show="isLoaded"
            :src="`https://dashboard.imnic.com.br/image.php?src=${ImageProps.imagem}&tam=gg`"
            @load="loadedImg"
            @error="errorImg"
          />
        </div>
      </div>
    </template>
  </modal-component>
</template>

<script>
import ModalVue from "./Modal.vue";
import Spinner from "@/components/Spinner.vue";

export default {
  name: "ImageModal",

  data() {
    return {
      hasImgError: false,
      isLoaded: false,
    };
  },

  components: {
    "modal-component": ModalVue,
    "spinner-component": Spinner,
  },
  props: {
    ImageProps: {
      type: Object,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    loadedImg() {
      this.isLoaded = true;
    },

    errorImg() {
      this.hasImgError = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  .image-container {
    display: flex;
    justify-content: center;
    max-height: 80vh;
    width: 80vh;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

@media (max-width: 540px) {
  .content {
    .image-container {
      max-width: 100%;
    }
  }
}
</style>
