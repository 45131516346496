<template>
  <div class="action-container">
    <div class="action-content" @click="actionClick(data)">
      <div class="icon">
        <i :class="data.icon" />

        <div v-if="hasAmount" class="amount">
          {{ amount }}
        </div>
      </div>

      <p>{{ data.text }}</p>
    </div>
    <dropdown-component
      v-if="hasDropdown && showDropdown"
      class="dropdown-component"
      :data="data.dropdownData"
      v-click-outside="closeDropdown"
      @newOption="redirect"
    />
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown";

export default {
  data() {
    return {
      amount: 0,
      items: {},
      hasAmount: false,
      hasDropdown: false,
      showDropdown: false,
    };
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          icon: "icon-user",
          text: "User",
          amount: 0,
          dropdownData: {
            hasNotBottomMargin: true,
            hasSelect: true,
            options: [
              {
                label: "Item",
                value: "item1",
                isActive: false,
                isBold: true,
              },

              {
                label: "Item",
                value: "item2",
                isActive: false,
                hasLine: true,
              },
            ],
          },
        };
      },
    },
  },

  components: {
    "dropdown-component": Dropdown,
  },

  created() {
    this.hasAmount = !this.$service.isUndefined(this.data.amount);
    this.hasDropdown = !this.$service.isUndefined(this.data.dropdownData);
    this.getCart();
  },

  watch: {
    "$store.state.orderSize": function () {
      this.amount = this.$store.state.orderSize.cartSize;
    },
  },

  methods: {
    actionClick(action) {
      if (this.hasDropdown) {
        this.showDropdown = !this.showDropdown;
      } else {
        this.redirect(action);
      }
    },

    getCart() {
      let cart = JSON.parse(localStorage.getItem("itemCart"));
      this.$store.dispatch("getOrderSize", cart).then((response) => {
        this.amount = response.cartSize;
      });
    },

    redirect(option) {
      if (this.$service.toUpperCase(option.value) == "LOGOUT") {
        this.hasDropdown = false;
        this.$store.dispatch("logout");
        this.$router.push("/login").catch(() => {});
      } else {
        this.$router.push(option.path).catch(() => {});
      }
    },

    closeDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-right: 1.5rem;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .action-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      display: flex;
      -webkit-box-align: center;
      align-items: center;

      i {
        font-size: 1.5rem;
      }

      .amount {
        transform: translate(100%, -70%);
        width: 1rem;
        height: 1rem;
        background: #ff9c00;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        font-size: 0.6rem;
        color: #fff;
        font-weight: 800;
        position: absolute;
      }
    }

    p {
      font-weight: 500;
      margin-top: 0.3rem;
      text-align: center;
    }
  }

  &:last-child {
    margin-right: 0;
  }

  .dropdown-container {
    top: 3.6rem;
    right: 2rem;
  }
}

@media (max-width: 540px) {
  .action-container {
    .action-content {
      p {
        display: none;
      }
    }
    .dropdown-container {
      top: 2.3rem;
      right: 0.5rem;
    }
  }
}
</style>
