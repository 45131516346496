<template>
  <div class="header-container">
    <div class="header-content">
      <div
        class="item-container"
        v-for="(headerItem, index) in data"
        :key="index"
      >
        <div
          class="item"
          @click.prevent="
            headerItem.isActive
              ? closeSectionState()
              : toggleSectionState(headerItem)
          "
        >
          {{ headerItem.text }}

          <i
            :class="
              headerItem.isActive
                ? 'icon-keyboard-arrow-up'
                : 'icon-keyboard-arrow-down'
            "
          />
        </div>

        <div
          v-if="headerItem.isActive"
          class="section animate__animated animate__slideInLeft"
          v-click-outside="closeSectionState"
        >
          <div
            v-for="(subCategorys, index) in data[index].subCategorys"
            :key="index"
            class="item"
            :class="{ actived: subCategorys.isActive }"
            @click="clickItem(subCategorys)"
          >
            {{ subCategorys.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "animate.css";

export default {
  name: "Header",

  computed: {
    ...mapGetters(["getHeaderShowSection"]),
  },

  props: {
    data: {
      type: Array,
      default: () => [
        {
          name: "Todas categorias",
          isActive: false,
          items: [
            {
              text: "Item 1",
              value: "",
              isActive: true,
            },

            {
              text: "Item 2",
              value: "",
              isActive: false,
            },

            {
              text: "Item 3",
              value: "",
              isActive: false,
            },
          ],
        },

        {
          name: "IM Pay",
          isActive: false,
          items: [
            {
              text: "Item 4",
              value: "",
              isActive: true,
            },

            {
              text: "Item 5",
              value: "",
              isActive: false,
            },

            {
              text: "Item 6",
              value: "",
              isActive: false,
            },
          ],
        },
      ],
    },
  },

  created() {
    this.currentLabel = this.setCurrentLabel();
  },

  methods: {
    toggleSectionState(item) {
      this.closeSectionState();
      item.isActive = !item.isActive;
      this.$store.state.headerShowSection = item.isActive;
    },

    clickItem(item) {
      this.data.forEach((item) => {
        item.subCategorys.map((item) => (item.isActive = false));
      });
      item.isActive = !item.isActive;
      this.$router.push({
        name: "Marketplace",
        query: {
          category: item.value,
        },
      });
      this.$store.commit("filterCategory", item.value);
      this.$emit("itemClick");
      this.closeSectionState();
    },

    setCurrentLabel() {
      // let activeCategory = this.data.filter(item => item.isActive)[0];
      // return this.$service.splitFirstName(activeCategory.text) + ' ' + this.$service.splitLastName(activeCategory.text);
    },

    closeSectionState() {
      this.data.map((item) => (item.isActive = false));
      this.$store.state.headerShowSection = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container {
  background: #ff9c00;
  padding: 0 1rem;
  overflow: auto;
  margin-left: auto;
  justify-content: center;
  display: flex;

  .header-content {
    width: 87.5rem;
    padding: 1rem;
    max-width: 100rem;

    .item-container {
      margin-right: 1rem;

      .item {
        font-size: 0.875rem;
        color: #fff;
        display: flex;
        font-weight: 500;
        cursor: pointer;
        align-items: center;
        width: max-content;

        i {
          font-size: 0.6rem;
          margin-left: 0.2rem;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .section {
      left: 0;
      margin-top: 1rem;
      position: absolute;
      width: 100%;
      padding: 1rem 2rem;
      background: #e8e8e8;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 0.2rem;
      grid-column-gap: 1rem;
      cursor: default;
      min-width: 20rem;
      z-index: 10;
      overflow: auto;

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 400;
        color: #333;
        padding: 0.5rem;
        border-radius: 0.3rem;
        cursor: pointer;

        &:hover {
          background: rgb(255 156 0 / 30%);
          cursor: pointer;
        }

        &.actived {
          background: rgb(255 156 0);
          color: rgb(88, 56, 56);
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 835px) {
  .header-container {
    .header-content {
      .section {
        padding: 1rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@media (max-width: 540px) {
  .header-container {
    .header-content {
      padding: 1rem;
      .section {
        padding: 1rem;
        grid-template-columns: repeat(1, 1fr);
        overflow-y: auto;
        overflow-x: hidden;

        .item {
          width: 100%;
          justify-content: flex-start;
          text-align: left;
        }
      }
    }
  }
}
</style>
