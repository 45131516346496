<template>
  <div class="content-card">
    <div class="cart-item">
      <div class="image-container">
        <img
          :src="`https://dashboard.imnic.com.br/image.php?src=${itemProps.imagem}&tam=p`"
          alt=""
        />
      </div>

      <div class="product-container">
        <p>{{ itemProps.nome }}</p>
      </div>

      <div class="amount-container">
        <div
          class="icon-container"
          :class="{ disabled: itemProps.qt <= 1 }"
          @click="itemReduce()"
        >
          <i class="icon-remove" />
        </div>

        <div style="max-width: 4.375rem">
          <input-field-component
            @att="refreshCart()"
            id="qt"
            class="input-field-component"
            v-model="itemProps.qt"
            :maxLength="3"
            :isQt="true"
            :type="'number'"
          />
        </div>

        <div class="icon-container" @click="itemProps.qt++, refreshCart()">
          <i class="icon-add" />
        </div>
      </div>

      <div class="price-container">
        <p>{{ priceFormatter(itemProps.qt * itemProps.valor) }}</p>
      </div>

      <div class="remove-container" @click="deleteItemCart(itemProps.codigo)">
        <i class="icon-trash" />
        <p>Remover</p>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script>
import InputField from "@/components/InputField";

export default {
  name: "Cart-items",

  components: {
    "input-field-component": InputField,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {
          item: {},
        };
      },
    },

    itemProps: {
      type: Object,
    },
  },

  methods: {
    priceFormatter(value) {
      return this.$service.priceFormatter(value);
    },
    itemReduce() {
      if (this.itemProps.qt > 1) {
        this.itemProps.qt--;
        this.refreshCart();
      }
    },
    refreshCart() {
      if (this.itemProps.qt < 1 || this.itemProps.qt > 999) {
        this.itemProps.qt = 1;
      }
      this.$emit("refreshCart");
    },
    deleteItemCart(value) {
      this.$emit("deleteItemCart", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.content-card {
  .cart-item {
    display: grid;
    grid-template-columns: 1fr 3fr 2fr 1fr 1fr;
    align-items: center;
    padding: 0.5rem 0 0.2rem;

    .image-container {
      width: 3rem;
      height: 3rem;
      img {
        border-radius: 0.3rem;
        width: 100%;
        height: 100%;
      }
    }

    .product-container {
      width: 16rem;
    }

    .amount-container {
      display: flex;
      color: #ff8b00;
      align-items: center;

      .input-field-component {
        margin-bottom: 0;
      }

      .icon-container {
        display: flex;
        align-items: center;
        margin: 0.2rem;
        padding: 0.23rem;
        border-radius: 100%;

        &.disabled {
          color: #8e8e8e;
          cursor: not-allowed;
        }

        &:hover {
          background: rgb(255 139 0 / 25%);
          cursor: pointer;
        }
      }
    }

    .remove-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      color: #ff5a5a;
      cursor: pointer;

      p {
        font-weight: 500;
      }
    }
  }
  .line {
    margin: 0.1rem 0;
    height: 0.01rem;
    width: 100%;
    background: #e2e2e2;
  }
}

@media (max-width: 500px) {
  .content {
    .cart-item {
      grid-template-columns: 1fr 1.5fr 1fr;
      .image-container {
        grid-column-start: 1;
      }
      .product-container {
        grid-column-start: 2;
        grid-column-end: 4;
      }
      .amount-container {
        grid-row-start: 2;
        grid-column-start: 2;
        justify-content: end;
      }
    }
  }
}
</style>
