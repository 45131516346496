<template>
  <div class="view-container" id="view-container">
    <slot name="alert" />

    <slot name="modal" />

    <div class="view-content">
      <nav-bar-component
        :actions="navBarActionData"
        :hasSearchInput="hasSearchInput"
        @search="search()"
      />

      <header-component
        v-if="hasHeader"
        :data="getCategorys"
        @itemClick="filterCategory()"
      />
      <!-- :data="getCategorys"  -->

      <div
        class="view animate__animated animate__fadeIn"
        :class="{ 'has-modal': getHeaderShowSection }"
      >
        <slot name="content" />
      </div>
    </div>
    <div class="footer" v-if="hasFooter">
      <div class="logo-container"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NavBar from "@/components/Base/NavBar";
import Header from "@/components/Base/Header";
import Vue from "vue";

export default {
  name: "DefaultView",

  data() {
    return {
      navBarData: [],
    };
  },

  computed: {
    ...mapGetters([
      "getAuthenticatedStatus",
      "getHeaderShowSection",
      "getCurrentUserData",
      "getCategorys",
    ]),
  },

  components: {
    "nav-bar-component": NavBar,
    "header-component": Header,
  },

  props: {
    hasHeader: {
      type: Boolean,
      default: false,
    },

    hasFooter: {
      type: Boolean,
      default: true,
    },

    hasSearchInput: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.navBarActionData = this.initialNavBarActionData();
    this.$store.state.headerShowSection = false;
  },

  watch: {
    getAuthenticatedStatus() {
      this.navBarActionData = this.initialNavBarActionData();
    },
  },

  methods: {
    filterCategory() {
      this.$emit("filterCategory");
    },

    search() {
      // this.$emit("search");
    },

    initialNavBarActionData() {
      let navBarActionData = [
        {
          text: "Carrinho",
          value: "cart",
          icon: "icon-cart",
          path: "/cart",
          amount: 0,
        },

        {
          text: "Entrar",
          value: "user",
          icon: "icon-user",
        },
      ];

      let userActionObj = navBarActionData.filter(
        (item) => this.$service.toUpperCase(item.value) == "USER"
      )[0];

      if (this.getAuthenticatedStatus) {
        this.$store.state.fullName = Vue.cookie.get("user");

        userActionObj.text = Vue.cookie.get("user");
        userActionObj.dropdownData = {
          options: [
            {
              label: "Meus pedidos",
              value: "myItems",
              path: "/orders",
            },
            {
              label: "Meu endereço",
              value: "Myaddress",
              path: "/address",
            },

            {
              label: "Sair",
              value: "logout",
            },
          ],
        };
      } else {
        this.$router.push("/login");
        userActionObj.text = "Entrar";
        userActionObj.path = "/login";
      }

      return navBarActionData;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-container {
  .has-modal {
    overflow: hidden;
    width: 100vw;
    min-width: 20rem;

    & > *:not(.modal) {
      pointer-events: none;
      -webkit-filter: blur(0.1rem);
      filter: blur(0.1rem);
    }
  }

  .view-content {
    min-height: calc(100vh - 4rem);
  }

  .view {
    display: flex;
    justify-content: center;
  }

  .footer {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 4rem;
    bottom: 0;
    background-color: #aa1428;
    z-index: 1;

    .logo-container {
      margin-top: 0.5rem;
      background: no-repeat;
      background-image: url("~@/assets/images/logo-instmix-min.png");
      width: 4rem;
    }
  }
}
</style>
