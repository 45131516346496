<template>
  <div
    class="dropdown-container"
    :class="{ 'margin-bottom': !data.hasNotBottomMargin }"
  >
    <div
      v-for="(item, index) in data.options"
      :key="index"
      class="option"
      :class="{
        active: item.isActive && data.hasSelect,
        bold: item.isBold,
        line: item.hasLine,
      }"
      @click="toggleOption(item)"
    >
      <i :class="item.icon" />

      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",

  props: {
    data: {
      type: Object,
      default() {
        return {
          hasNotBottomMargin: true,
          hasSelect: true,
          options: [
            {
              label: "Item",
              value: "item1",
              icon: "icon-close",
              isActive: false,
              isBold: true,
            },

            {
              label: "Item",
              value: "item2",
              icon: "icon-close",
              isActive: false,
              hasLine: true,
            },
          ],
        };
      },
    },
  },

  methods: {
    toggleOption(current) {
      if (!this.$service.isUndefined(current.isActive)) {
        this.data.options.map((item) => (item.isActive = false));
        current.isActive = true;
      }

      this.$emit("newOption", current);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-container {
  position: absolute;
  background: #fff;
  padding-top: 0.5rem;
  min-width: 11rem;
  border-radius: 0.3rem;
  box-shadow: 0 0 1.25rem rgba(0, 0, 0, 0.2);
  font-size: 0.875rem;
  z-index: 2;

  &.margin-bottom {
    padding-bottom: 0.5rem;
  }

  .option {
    height: 2.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;
    color: #333;

    &.bold {
      font-weight: 500;
    }

    &.line {
      border-bottom: 0.1rem solid #dadada;
    }

    &:hover {
      background: #f6f7f7;
    }

    &.active {
      background: #ff8b00;
      color: #fff;

      i {
        color: #fff;
      }
    }

    i {
      margin-right: 0.5rem;
      color: #8e8e8e;
    }
  }
}
</style>
