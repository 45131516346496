<template>
  <div class="container">
    <div v-if="hasRequest">
      <spinner-component fontSize="1.5rem" color="#FF8B00" />
    </div>

    <p
      v-else-if="isDisabled"
      class="pagination-button"
      v-ripple="'rgba(255, 139, 0, 10)'"
      @click="onClick"
    >
      Mostrando {{ data.countItems }} de {{ pageProps }}
    </p>

    <div class="icon-container" v-if="currentPage > 0" @click="previousPage()">
      <i class="icon-keyboard-arrow-left" />
    </div>

    <p class="pagination-button disabled">
      Página {{ currentPage + 1 }} de {{ pageProps }}
    </p>

    <div
      class="icon-container"
      v-if="currentPage < pageProps - 1"
      @click="nextPage()"
    >
      <i class="icon-keyboard-arrow-right" />
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/Spinner";

export default {
  name: "PaginationButton",
  props: {
    data: {
      type: Object,
      default() {
        return {
          countItems: "x",
          totalCountItems: "2x",
        };
      },
    },
    currentPage: {
      type: Number,
    },

    pageProps: {
      type: Number,
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    hasRequest: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    "spinner-component": Spinner,
  },

  mounted() {},

  methods: {
    previousPage() {
      this.$emit("previousPage", this.currentPage);
    },
    nextPage() {
      this.$emit("nextPage", this.currentPage + 2);
    },
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 1rem;
  max-width: 100%;
  .pagination-button {
    width: fit-content;
    font-weight: 500;
    color: #ff8b00;
    text-align: center;
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;

    // &.disabled {
    //   cursor: not-allowed;
    //   color: #EFEFEF;
    //   opacity: 0.6;

    //   &:hover {
    //     background: transparent;
    //   }
    // }

    &:hover {
      background: rgba(255, 156, 0, 0.1);
    }
  }

  .icon-container {
    cursor: pointer;
    color: #ff8b00;
  }
}
</style>
