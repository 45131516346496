import axios from "axios";
import Vue from "vue";
import router from "@/router";
import store from "@/store";

export const instance = () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_IMNIC_API_URL,
    timeout: 5000,
    headers: {
      "Content-Type": "application/json",
      "X-Access-Token": Vue.cookie.get("token"),
    },
  });

  instance.interceptors.response.use(
    (response) => {
      var responseData = response.data;

      if (response.data.status === 401) {
        Vue.cookie.set("token", null);
        store.dispatch("logout");
        router.push("/login").catch(() => {});

        return Promise.reject(responseData);
      } else if (response.data.error) {
        return Promise.reject(responseData);
      } else {
        return responseData;
      }
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return instance;
};

export default instance;
